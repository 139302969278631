import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { DataProcessingStatusProps } from '../../../types/reduxTypes/dataProcessingStatus';

const createReducer = <T extends SliceCaseReducers<DataProcessingStatusProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDataProcessingStatusData(state, action) {
    state.dataProcessingStatusData = action.payload;
  }
});

export default reducers;
