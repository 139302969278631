export const newColumnDropdownOptions = [
  { id: 1, label: 'log10' },
  { id: 2, label: 'ln' },
  { id: 3, label: 'sqrt' },
  { id: 4, label: 'exp' },
  { id: 5, label: 'sin' },
  { id: 6, label: 'cos' },
  { id: 7, label: 'sum' },
  { id: 8, label: 'subtraction' },
  { id: 9, label: 'multiplication' },
  { id: 10, label: 'division' }
];
