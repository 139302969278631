import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import {
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsAutocompleteStyles,
  columnsDropdownWrapperStyles,
  firstRowOptionsStyles,
  columnNameInputStyles,
  columnNameMarginStyles,
  scrollBlockStyles
} from './convertNumericToCategoricalBody.styles';
import ConvertNumericToCategoricalInnerBlock from './convertNumericToCategoricalInnerBlock/convertNumericToCategoricalInnerBlock.component';
import { emptyBlock } from './convertNumericToCategoricalBody.constants';
import { IConvertNumericToCategoricalBody } from './convertNumericToCategoricalBody.models';
import { AddPreprocessButton } from '../../../../../../components/Buttons/addPreprocessButton';
import { isNaN } from 'formik';

const ConvertNumericToCategoricalBody: React.FC<IConvertNumericToCategoricalBody> = ({
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [columnNameInputValue, setColumnNameInputValue] = useState<string>(
    block.singleActionBody.outputColumnName?.length > 0 ? block.singleActionBody.outputColumnName : ''
  );
  const [innerBlocks, setInnerBlocks] = useState<any>(
    block.singleActionBody.subActionValue?.length > 0
      ? block.singleActionBody.subActionValue.map((blockBody: any, index: number) => ({
          id: index + 1,
          blockBody: blockBody.length === 3 ? blockBody : ['', '', ''] // Ensure structure
        }))
      : [emptyBlock({ id: 1 })]
  );
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);

  const allFieldsAreFilled =
    selectedColumns.length > 0 &&
    columnNameInputValue.length > 0 &&
    innerBlocks
      .slice(0, 2)
      .every(
        (block: any) => Array.isArray(block.blockBody) && !isNaN(block.blockBody[0]) && !isNaN(block.blockBody[1])
      ) &&
    !startError &&
    !endError;

  const handleColumnsChange = (_: any, newValue: { id: number; label: string } | null) => {
    setSelectedColumns(newValue ? [newValue.label] : []);
  };

  const onAddClick = () => {
    if (!innerBlocks.length) {
      setInnerBlocks([{ id: 0, action: '' }]);
    } else if (innerBlocks.length < 10) {
      setInnerBlocks((prev: any) => [...prev, { id: innerBlocks[innerBlocks.length - 1].id + 1 }]);
    }
  };

  const deleteInnerBlock = (innerBlockId: any) => {
    setInnerBlocks((prev: any) => {
      const filteredInnerBlocks = prev.filter((innerBlock: any) => innerBlock.id !== innerBlockId);
      return filteredInnerBlocks.length > 0 ? filteredInnerBlocks : [emptyBlock({ id: 1 })];
    });
  };

  const updateBlockBody = (innerBlocks: any, targetBlockId: number, newBlockBody: any) => {
    return innerBlocks.map((innerBlock: any) => {
      if (innerBlock.id === targetBlockId) {
        return {
          ...innerBlock,
          blockBody: newBlockBody // Update the block's singleActionBody
        };
      }
      return innerBlock;
    });
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: '',
      subActionValue: innerBlocks?.map((block: any) => (block?.blockBody ? Object.values(block.blockBody) : [])),
      outputColumnName: columnNameInputValue,
      isCompleted: allFieldsAreFilled
    };
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
  }, [selectedColumns, innerBlocks, columnNameInputValue]);

  return (
    <div style={bodyStyles}>
      <div style={firstRowOptionsStyles}>
        <div style={columnsDropdownWrapperStyles}>
          <Autocomplete
            value={dropdownOptions.find((option: any) => option.label === selectedColumns[0]) || null}
            options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
            onChange={handleColumnsChange}
            sx={columnsAutocompleteStyles}
            renderOption={(props, option) => (
              <li {...props} style={columnsAutocompleteLiStyles}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="select column"
                label=""
                InputProps={{
                  ...params.InputProps,
                  style: columnsAutocompleteLiStyles
                }}
                sx={columnsAutocompletePlaceholderStyles}
              />
            )}
            componentsProps={{
              clearIndicator: {
                onClick: () => setSelectedColumns([])
              }
            }}
          />
        </div>
        <div>
          <input
            value={columnNameInputValue}
            onChange={(e) => setColumnNameInputValue(e.target.value.slice(0, 40))}
            placeholder="output column"
            style={{ ...columnNameInputStyles, ...columnNameMarginStyles }}
          />
        </div>
      </div>
      <div style={scrollBlockStyles}>
        {innerBlocks.map((innerBlock: any) => (
          <div key={innerBlock.id}>
            <ConvertNumericToCategoricalInnerBlock
              startError={startError}
              setStartError={setStartError}
              endError={endError}
              setEndError={setEndError}
              setInnerBlocks={setInnerBlocks}
              updateBlockBody={updateBlockBody}
              innerBlock={innerBlock}
              innerBlocks={innerBlocks}
              element={innerBlock.id}
              deleteInnerBlock={deleteInnerBlock}
            />
          </div>
        ))}
        {innerBlocks.length <= 9 && (
          <AddPreprocessButton
            convert={true}
            blocks={blocks}
            handlePlusButtonClick={onAddClick}
            title="Add"
            marginZero={true}
          />
        )}
      </div>
    </div>
  );
};

export default ConvertNumericToCategoricalBody;
