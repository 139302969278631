import { Delete, Download, IosShare, Visibility } from '@mui/icons-material';

import { dispatch } from '../../../store/hooks';
import { modalsMiddleware } from '../../../store/slices/modals';
import { IDashboardAction } from '../../../types';
import { ModalName, SeveritiesType } from '../../../types/modals';

const shareDialogOpen = ({ fileName }: { fileName: string }) => {
  dispatch(modalsMiddleware.openModal({ name: ModalName.ShareInfoModal, props: { fileName } }));
};

const deleteDialogOpen = (
  { fileName }: { fileName: string },
  { fileSize }: { fileSize: number },
  { id }: { id: number },
  { setChecked }: { setChecked: any },
  { shapValueWanted }: { shapValueWanted: boolean },
  { isModels }: { isModels: boolean },
  { selected }: { selected: any },
  { setSelected }: { setSelected: any }
) => {
  dispatch(
    modalsMiddleware.openModal({
      name: ModalName.DeleteModal,
      props: { fileName, fileSize, isModels, selected, setSelected }
    })
  );
};

const summaryDialogOpen = ({ fileName }: { fileName: string }, { fileSize }: { fileSize: number }) => {
  dispatch(modalsMiddleware.openModal({ name: ModalName.DatasourceSummaryModal, props: { fileName, fileSize } }));
};
const previewDialogOpen = (
  { fileName }: { fileName: string },
  { fileSize }: { fileSize: number },
  { id }: { id: number },
  { setChecked }: { setChecked: any }
) => {
  dispatch(modalsMiddleware.openModal({ name: ModalName.PreviewModal, props: { fileName, fileSize, setChecked } }));
};

const toastNotification = () => {
  dispatch(
    modalsMiddleware.setToastNotificationPopUpState({
      open: true,
      props: {
        severityType: SeveritiesType.error,
        description: 'Hello, i am error'
      }
    })
  );
};

export const DatasourceActions: IDashboardAction[] = [
  // {
  //   title: 'Share',
  //   name: 'share',
  //   style: {
  //     backgroundColor: '#FCA311',
  //     color: '#ffffff',
  //     '&:hover': {
  //       backgroundColor: '#F77F00'
  //     }
  //   },
  //   color: '#FCA311',
  //   state: true,
  //   openFunction: shareDialogOpen,
  //   icon: IosShare
  // },
  // {
  //   title: 'Preview',
  //   name: 'preview',
  //   style: {
  //     backgroundColor: '#FCA311',
  //     color: '#ffffff',
  //     '&:hover': {
  //       backgroundColor: '#F77F00'
  //     }
  //   },
  //   color: 'primary',
  //   state: true,
  //   openFunction: previewDialogOpen,
  //   icon: Visibility
  // },
  {
    title: 'Summary',
    name: 'summary',
    style: {
      backgroundColor: '#3DA5D9',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#2364AA'
      }
    },
    color: 'primary',
    state: true,
    openFunction: summaryDialogOpen,
    icon: Visibility
  },
  {
    title: 'Download',
    name: 'download',
    style: {
      color: '#ffffff',
      backgroundColor: '#0EAD96',
      '&:hover': {
        backgroundColor: '#1A936F'
      }
    },
    color: '#0EAD96',
    state: true,
    openFunction: toastNotification,
    icon: Download
  },
  {
    title: 'Delete',
    name: 'delete',
    style: {
      backgroundColor: '#EF233C',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#D90429'
      }
    },
    color: 'error',
    state: true,
    openFunction: deleteDialogOpen,
    icon: Delete
  }
];
