import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setDataProcessingProgressData } = slice.actions;

const getDataProcessingProgress = (url: any) => async () => {
  try {
    const response = await API.dataProcessingProgress.dataProcessingProgress(url);
    dispatch(setDataProcessingProgressData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getDataProcessingProgress
};
