import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {};

export const containerStyles: React.CSSProperties = {
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex'
};

export const labelStyles: React.CSSProperties = {
  color: '#76787F',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  lineHeight: '15.18px',
  paddingBottom: '20px'
};

export const labelFileNameStyles: React.CSSProperties = {
  color: '#3D4047',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  lineHeight: '15.18px',
  paddingBottom: '20px'
};

export const inputStyles: React.CSSProperties = {
  display: 'flex',
  marginBottom: '20px'
};

export const autocompleteStyles: SxProps<Theme> = {
  width: '354px',
  height: '36px',
  marginRight: '10px',
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    minHeight: '36px',
    '& fieldset': {
      border: '1px solid #76787F !important',
      borderColor: '#000',
      borderRadius: '4px'
    }
  },
  '& .MuiAutocomplete-listbox': {
    fontFamily: 'Circular Std Book !important'
  }
};

export const autocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const autocompleteLiStyles: React.CSSProperties = { fontFamily: 'Circular Std Book' };

export const autocompleteIconStyles: React.CSSProperties = {
  marginLeft: '10px',
  marginRight: '4px',
  width: '20px',
  height: '20px'
};

export const inputButtonStyles: React.CSSProperties = {
  width: '36px',
  minWidth: '36px',
  height: '36px',
  backgroundColor: '#334063',
  borderRadius: '8px',
  padding: '0'
};

export const inputButtonDisabledStyles: React.CSSProperties = {
  width: '36px',
  minWidth: '36px',
  height: '36px',
  backgroundColor: '#B3B5B9',
  borderRadius: '8px',
  padding: '0'
};

export const inputButtonIconStyles: React.CSSProperties = {
  color: '#fff',
  fontSize: '12px',
  display: 'inline-flex',
  alignItems: 'center'
};

export const inputButtonIconInnerStyles: React.CSSProperties = {
  color: '#fff',
  width: '12px',
  height: '12px'
};

export const orStyles: React.CSSProperties = {
  display: 'block',
  textAlign: 'center',
  color: '#B3B5B9',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  lineHeight: '15.18px',
  marginBottom: '20px'
};

export const uploadButtonStyles: React.CSSProperties = {
  height: '36px',
  width: '100%',
  color: '#fff',
  backgroundColor: '#334063',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontWeight: '450',
  fontSize: '14px',
  marginBottom: '20px'
};

export const uploadFileNameStyles: React.CSSProperties = {
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontWeight: '450',
  fontSize: '14px',
  minWidth: '100%',
  textAlign: 'center'
};

export const cloudIconStyles: React.CSSProperties = {
  fontSize: '16px'
};

export const uploadButtonSpanStyles: SxProps<Theme> = {
  '& .MuiButton-startIcon': {
    marginLeft: '0',
    marginRight: '10px'
  }
};
