import React, { useState } from 'react';
import { removeLastExtension } from '../../../../../helpers/removeExtension';
import {
  containerStyles,
  labelFileNameStyles,
  labelStyles,
  extensionErrorStyles,
  newFilenameInputStyles,
  dropdownWrapperStyles,
  actionsAutocompleteLiStyles,
  actionsAutocompletePlaceholderStyles,
  actionsAutocompleteStyles,
  scrollBlockStyles
} from './processingMergeJoin.styles';
import { IProcessingMergeJoin } from './processingMergeJoin.model';
import { Autocomplete, TextField } from '@mui/material';
import { ReactComponent as ConcatJoinIcon } from './../../../../../assets/svg/concatJoinIcon.svg';
import { ReactComponent as LeftJoinIcon } from './../../../../../assets/svg/leftJoinIcon.svg';
import { ReactComponent as RightJoinIcon } from './../../../../../assets/svg/rightJoinIcon.svg';
import { ReactComponent as InnerJoinIcon } from './../../../../../assets/svg/innerJoinIcon.svg';
import { ReactComponent as OuterJoinIcon } from './../../../../../assets/svg/outerJoinIcon.svg';
import { ReactComponent as FullJoinIcon } from './../../../../../assets/svg/fullJoinIcon.svg';
import { ReactComponent as CrossJoinIcon } from './../../../../../assets/svg/crossJoinIcon.svg';
import { AddPreprocessButton } from '../../../../../components/Buttons/addPreprocessButton';
import { makeStyles } from '@mui/styles';
import { IBlock } from '../../../processingPreprocess/processingPreprocess.models';
import ProcessingMergeJoinInnerBlock from './processingMergeJoinInnerBlock/processingMergeJoinInnerBlock.component';

const useStyles = makeStyles({
  root: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none', // Hide scrollbar in Firefox
    '-ms-overflow-style': 'none' // Hide scrollbar in IE and Edge
  }
});

const ProcessingMergeJoin: React.FC<IProcessingMergeJoin> = ({
  summaryData,
  processingMergeInputData,
  processingMergeNewFileNameValue,
  setProcessingMergeNewFileNameValue,
  processingMergeJoinBlocks,
  setProcessingMergeJoinBlocks
}) => {
  const classes = useStyles();
  const [extensionError, setExtensionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedActionOption, setSelectedActionOption] = useState<any>(null); // Store only `id` and `label` in the state

  const joinOptions = [
    { id: 1, label: 'concat', icon: <ConcatJoinIcon /> },
    { id: 2, label: 'Left Join', icon: <LeftJoinIcon /> },
    { id: 3, label: 'Right Join', icon: <RightJoinIcon /> },
    { id: 4, label: 'Inner Join', icon: <InnerJoinIcon /> },
    { id: 5, label: 'Outer Join', icon: <OuterJoinIcon /> },
    { id: 6, label: 'Full Join', icon: <FullJoinIcon /> },
    { id: 7, label: 'Cross Join', icon: <CrossJoinIcon /> }
  ];

  const newFileNameValueChange = (event: any) => {
    const value = event.target.value;

    const validPattern = /^[a-zA-Z0-9_-]*$/;

    if (validPattern.test(value)) {
      setProcessingMergeNewFileNameValue(value);
      if (value.startsWith('-') || value.startsWith('_') || value.endsWith('-') || value.endsWith('_')) {
        setExtensionError(true);
        setErrorMessage('The dataset name cannot start and end with a special character.');
      } else {
        setExtensionError(false);
        setErrorMessage('');
      }
    } else {
      setProcessingMergeNewFileNameValue(value);
      setExtensionError(true);
      setErrorMessage('Spaces or special symbols are not allowed.');
    }
  };

  const onPlusClick = () => {
    if (!processingMergeJoinBlocks.length) {
      setProcessingMergeJoinBlocks([{ id: 0, action: '', singleActionBody: {} }]);
    } else if (processingMergeJoinBlocks.length < 10) {
      setProcessingMergeJoinBlocks((prev: any) => [
        ...prev,
        { id: processingMergeJoinBlocks[processingMergeJoinBlocks.length - 1].id + 1, singleActionBody: {} }
      ]);
    }
  };

  const deleteBlock = (blockId: number) => {
    setProcessingMergeJoinBlocks((prevBlocks: IBlock[]) => {
      const updatedBlocks = prevBlocks.filter((block) => block.id !== blockId);
      return updatedBlocks.map((block, index) => ({
        ...block,
        id: index + 1 // Reassign sequential IDs
      }));
    });
  };

  console.log(selectedActionOption);

  return (
    <div style={containerStyles}>
      <span style={labelStyles}>
        Combining <span style={labelFileNameStyles}>{summaryData.filename}</span> with{' '}
        <span style={labelFileNameStyles}>
          {processingMergeInputData.dataset.length > 0
            ? removeLastExtension(processingMergeInputData.dataset)
            : removeLastExtension(processingMergeInputData?.uploadedFileName)}
        </span>
      </span>
      <div>
        <input
          style={newFilenameInputStyles}
          onChange={newFileNameValueChange}
          placeholder="New dataset name"
          value={processingMergeNewFileNameValue}
        />
        {extensionError && <p style={extensionErrorStyles}>{errorMessage}</p>}
      </div>
      <div style={dropdownWrapperStyles}>
        <Autocomplete
          value={selectedActionOption}
          options={joinOptions}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedActionOption({
                id: newValue.id,
                label: newValue.label
              });
            } else {
              setSelectedActionOption(null);
            }
          }}
          // Set `disableClearable` to false to ensure the clear button is available when a selection is made
          disableClearable={false}
          sx={actionsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={actionsAutocompleteLiStyles}>
              {option.icon} {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select joining method"
              label=""
              InputProps={{
                ...params.InputProps,
                style: actionsAutocompleteLiStyles
              }}
              sx={actionsAutocompletePlaceholderStyles}
            />
          )}
        />
      </div>
      {selectedActionOption?.label.length > 0 && (
        <div className={classes.root} style={scrollBlockStyles}>
          {processingMergeJoinBlocks.map((block, index) => (
            <div key={block.id}>
              <ProcessingMergeJoinInnerBlock
                summaryData={summaryData}
                processingMergeInputData={processingMergeInputData}
                setBlocks={setProcessingMergeJoinBlocks}
                block={block}
                blocks={processingMergeJoinBlocks}
                element={block.id}
                deleteBlock={deleteBlock}
                // modelVisualisationsData={modelVisualisationsData}
                // selectedActionOption={selectedActionOption}
                // setSelectedActionOption={setSelectedActionOption}
                // updateSingleActionBody={updateSingleActionBody}
                // data={data?.preview_data}
                // summaryData={summaryData?.summary?.data_summary}
              />
            </div>
          ))}
          {processingMergeJoinBlocks.length <= 9 && (
            <AddPreprocessButton
              isMerge
              convert={false}
              // data={data?.preview_data}
              processingMergeJoinBlocks={processingMergeJoinBlocks}
              handlePlusButtonClick={onPlusClick}
              title="Add Step"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProcessingMergeJoin;
