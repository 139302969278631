import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { actionsDropdownOptions, getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import {
  actionsOptionsParentStyles,
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsDropdownWrapperStyles,
  formControlStyles,
  parentActionFirstOptionStyles,
  parentActionLabelStyles,
  parentActionRadioStyles
} from './handleOutliersBody.styles';
import { IHandleOutliersBody } from './handleOutliersBody.models';
import { columnsAutocompleteStyles } from '../handleMissingValuesBody/handleMissingValuesBody.styles';

const HandleOutliersBody: React.FC<IHandleOutliersBody> = ({
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [selectedParentAction, setSelectedParentAction] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );

  const allFieldsAreFilled = selectedColumns.length > 0 && selectedParentAction.length > 0;

  const handleColumnsChange = (_: any, newValue: Array<{ id: number; label: string }>) => {
    setSelectedColumns(newValue.map((option) => option.label));
  };

  const handleParentActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParentAction(event.target.value as 'remove' | 'winsorize');
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: selectedParentAction,
      subActionValue: '',
      outputColumnName: '',
      isCompleted: allFieldsAreFilled
    };
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    if (selectedColumns.length === 0) {
      setSelectedParentAction('');
    }
  }, [selectedColumns, selectedParentAction]);

  return (
    <div style={bodyStyles}>
      <div style={columnsDropdownWrapperStyles}>
        <Autocomplete
          multiple
          value={dropdownOptions.filter((option: { id: number; label: string }) =>
            selectedColumns.includes(option.label)
          )}
          options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
          onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={columnsAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select columns"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedColumns([])
            }
          }}
        />
      </div>
      {selectedColumns.length > 0 && (
        <div>
          <RadioGroup
            value={selectedParentAction}
            onChange={handleParentActionChange}
            style={actionsOptionsParentStyles}
          >
            <FormControlLabel
              sx={formControlStyles}
              style={parentActionFirstOptionStyles}
              value="remove"
              control={<Radio sx={parentActionRadioStyles} />}
              label={<span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>remove</span>}
            />
            <FormControlLabel
              sx={formControlStyles}
              value="winsorize"
              control={<Radio sx={parentActionRadioStyles} />}
              label={<span style={parentActionLabelStyles}>winsorize</span>}
            />
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default HandleOutliersBody;
