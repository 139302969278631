import React from 'react';
import { DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import {
  buttonsStyles,
  cancelButtonStyles,
  containerStyles,
  continueButtonDisabledStyles,
  continueButtonStyles,
  labelFileNameStyles,
  labelStyles,
  progressStyles,
  uploadedFileNameStyles,
  uploadedFileSizeStyles
} from './processingMergeUploadDataset.styles';
import { IProcessingMergeUploadDataset } from './processingMergeUploadDataset.model';
import { removeLastExtension } from '../../../../../helpers/removeExtension';
import { MbConverter } from '../../../../../helpers/MbConverter';
import Progress from '../../../../../components/Progress';

const ProcessingMergeUploadDataset: React.FC<IProcessingMergeUploadDataset> = ({
  summaryData,
  setProcessingMergeActiveStep,
  processingMergeInputData,
  processingMergeUploadStatus,
  setProcessingMergeUploadStatus,
  processingMergeUploadProgress,
  setProcessingMergeUploadProgress,
  processingMergeUploadSucceeded
}) => {
  function onSubmit() {
    setProcessingMergeActiveStep(3);
    setProcessingMergeUploadProgress(0);
    setProcessingMergeUploadStatus('');
  }
  function handleBack() {
    setProcessingMergeActiveStep(1);
    setProcessingMergeUploadProgress(0);
    setProcessingMergeUploadStatus('');
  }

  return (
    <div style={containerStyles}>
      <span style={labelStyles}>
        Select a dataset to combine it with <span style={labelFileNameStyles}>{summaryData.filename}</span>
      </span>
      <div style={uploadedFileNameStyles}>{removeLastExtension(processingMergeInputData?.uploadedFileName)}</div>
      <div style={uploadedFileSizeStyles}>({MbConverter(processingMergeInputData.uploadedFileSize)})</div>
      <DialogContent sx={progressStyles}>
        <DialogContentText>
          <Progress
            uploadStatus={processingMergeUploadStatus}
            progress={processingMergeUploadProgress}
            setPredictionProgress={setProcessingMergeUploadProgress}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={buttonsStyles}>
        <Button variant="contained" style={cancelButtonStyles} onClick={handleBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          style={
            processingMergeUploadSucceeded || processingMergeUploadProgress === 100
              ? continueButtonStyles
              : continueButtonDisabledStyles
          }
          onClick={onSubmit}
          disabled={!processingMergeUploadSucceeded || processingMergeUploadProgress === 100}
        >
          Continue
        </Button>
      </DialogActions>
    </div>
  );
};

export default ProcessingMergeUploadDataset;
