import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton
} from '@mui/material';
import { ISaveModal } from './processingSaveModal.models';
import CloseIcon from '@mui/icons-material/Close';
import {
  buttonsStyles,
  cancelButtonStyles,
  closeIconStyles,
  containerStyles,
  continueButtonDisabledStyles,
  continueButtonStyles,
  fileNameStyles,
  fileSizeStyles,
  modalStyles,
  progressStyles,
  titleStyles
} from './processingSaveModal.styles';
import { MbConverter } from '../../../helpers/MbConverter';
import { removeLastExtension } from '../../../helpers/removeExtension';
import Progress from '../../../components/Progress';

const ProcessingSaveModal: React.FC<ISaveModal> = ({
  fileName,
  fileSize,
  setUploadStatus,
  uploadStatus,
  progress,
  setProgress,
  onContinue,
  open,
  onClose
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: modalStyles
      }}
    >
      <div style={containerStyles}>
        <DialogTitle style={titleStyles}>
          Preprocessing Dataset
          <IconButton onClick={onClose} sx={closeIconStyles}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div style={fileNameStyles}>{removeLastExtension(fileName)}</div>
        <div style={fileSizeStyles}>{MbConverter(fileSize)}</div>
        <DialogContent sx={progressStyles}>
          <DialogContentText>
            <Progress uploadStatus={uploadStatus} progress={progress} setPredictionProgress={setProgress} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={buttonsStyles}>
          <Button variant="contained" style={cancelButtonStyles} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ProcessingSaveModal;
