import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import {
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsAutocompleteStyles,
  columnsDropdownWrapperStyles,
  dropdownWrapperStyles,
  newColumnAutocompleteStyles,
  newColumnAutocompleteLiStyles,
  newColumnAutocompletePlaceholderStyles,
  newColumnInputStyles,
  childWrapperStyles
} from './createNewColumnBody.styles';
import { newColumnDropdownOptions } from './createNewColumnBody.constants';
import { ICreateNewColumnBody } from './createNewColumnBody.models';

const CreateNewColumnBody: React.FC<ICreateNewColumnBody> = ({
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? [block.singleActionBody.columns[0]] : []
  );
  const [selectedSecondColumns, setSelectedSecondColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? [block.singleActionBody.columns[1]] : []
  );
  const [selectedFunction, setSelectedFunction] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );
  const [newColumnInputValue, setNewColumnInputValue] = useState<string>(
    block.singleActionBody.outputColumnName?.length > 0 ? block.singleActionBody.outputColumnName : ''
  );

  const allFieldsAreFilled =
    selectedFunction.length > 0 &&
    newColumnInputValue.length > 0 &&
    (((selectedFunction === 'sum' ||
      selectedFunction === 'subtraction' ||
      selectedFunction === 'multiplication' ||
      selectedFunction === 'division') &&
      selectedColumns.length > 0 &&
      selectedSecondColumns.length > 0) ||
      ((selectedFunction === 'log10' ||
        selectedFunction === 'ln' ||
        selectedFunction === 'sqrt' ||
        selectedFunction === 'exp' ||
        selectedFunction === 'sin' ||
        selectedFunction === 'cos') &&
        selectedColumns.length > 0));

  const handleColumnsChange = (_: any, newValue: { id: number; label: string } | null) => {
    setSelectedColumns(newValue ? [newValue.label] : []);
  };

  const handleSecondColumnsChange = (_: any, newValue: { id: number; label: string } | null) => {
    setSelectedSecondColumns(newValue ? [newValue.label] : []);
  };

  const handleSelectedFunctionChange = (_: any, newValue: { id: number; label: string } | null) => {
    setSelectedFunction(newValue ? newValue.label : '');
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedSecondColumns.length > 0 ? [...selectedColumns, ...selectedSecondColumns] : selectedColumns,
      subAction: selectedFunction,
      outputColumnName: newColumnInputValue,
      isCompleted: allFieldsAreFilled
    };
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    if (selectedFunction.length === 0) {
      setSelectedColumns([]);
      setSelectedSecondColumns([]);
      setNewColumnInputValue('');
    }
  }, [selectedColumns, selectedFunction, selectedSecondColumns, newColumnInputValue]);

  // console.log(selectedColumns);
  // console.log(selectedSecondColumns);

  return (
    <div style={bodyStyles}>
      <div style={dropdownWrapperStyles}>
        <Autocomplete
          value={
            selectedFunction.length > 0
              ? newColumnDropdownOptions.find((option) => option.label === selectedFunction)
              : null
          }
          options={newColumnDropdownOptions}
          onChange={handleSelectedFunctionChange}
          sx={newColumnAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={newColumnAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select function"
              label=""
              InputProps={{
                ...params.InputProps,
                style: newColumnAutocompleteLiStyles
              }}
              sx={newColumnAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedFunction('')
            }
          }}
        />
      </div>
      {selectedFunction.length > 0 && (
        <div style={childWrapperStyles}>
          <div style={columnsDropdownWrapperStyles}>
            <Autocomplete
              value={dropdownOptions.find((option: any) => option.label === selectedColumns[0]) || null}
              options={dropdownOptions?.filter(
                (option: any) =>
                  !blocks.some(
                    (b) =>
                      b.action === 'Manage Columns' &&
                      ['drop', 'rename'].includes(b.singleActionBody?.subAction) &&
                      b.singleActionBody?.columns?.includes(option.label)
                  )
              )}
              onChange={handleColumnsChange}
              sx={columnsAutocompleteStyles}
              renderOption={(props, option) => (
                <li {...props} style={columnsAutocompleteLiStyles}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="select column"
                  label=""
                  InputProps={{
                    ...params.InputProps,
                    style: columnsAutocompleteLiStyles
                  }}
                  sx={columnsAutocompletePlaceholderStyles}
                />
              )}
              componentsProps={{
                clearIndicator: {
                  onClick: () => setSelectedColumns([])
                }
              }}
            />
          </div>
          {(selectedFunction === 'sum' ||
            selectedFunction === 'subtraction' ||
            selectedFunction === 'multiplication' ||
            selectedFunction === 'division') && (
            <div style={columnsDropdownWrapperStyles}>
              <Autocomplete
                value={dropdownOptions.find((option: any) => option.label === selectedSecondColumns[0]) || null}
                // options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
                options={dropdownOptions?.filter(
                  (option: any) =>
                    !blocks.some(
                      (b) =>
                        b.action === 'Manage Columns' &&
                        ['drop', 'rename'].includes(b.singleActionBody?.subAction) &&
                        b.singleActionBody?.columns?.includes(option.label)
                    )
                )}
                onChange={handleSecondColumnsChange}
                sx={columnsAutocompleteStyles}
                renderOption={(props, option) => (
                  <li {...props} style={columnsAutocompleteLiStyles}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="select column"
                    label=""
                    InputProps={{
                      ...params.InputProps,
                      style: columnsAutocompleteLiStyles
                    }}
                    sx={columnsAutocompletePlaceholderStyles}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setSelectedSecondColumns([])
                  }
                }}
              />
            </div>
          )}
          <div style={columnsDropdownWrapperStyles}>
            <input
              value={newColumnInputValue}
              onChange={(e) => setNewColumnInputValue(e.target.value.slice(0, 40))}
              placeholder="output column"
              style={newColumnInputStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNewColumnBody;
