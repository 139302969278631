import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const dataProcessingProgressManager = {
  dataProcessingProgress(url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default dataProcessingProgressManager;
