import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { DataProcessingProgressProps } from '../../../types/reduxTypes/dataProcessingProgress';

const createReducer = <T extends SliceCaseReducers<DataProcessingProgressProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDataProcessingProgressData(state, action) {
    state.dataProcessingProgressData = action.payload;
  }
});

export default reducers;
