export const dateTimeDropdownOptions = [
  { id: 1, label: 'year' },
  { id: 2, label: 'month' },
  { id: 3, label: 'day' },
  { id: 4, label: 'weekday' },
  { id: 5, label: 'weekend' },
  { id: 6, label: 'US Holiday' },
  { id: 7, label: 'hour' },
  { id: 8, label: 'sin(hour)' },
  { id: 9, label: 'cos(hour)' }
];
