import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const labelStyles: React.CSSProperties = {
  color: '#76787F',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  lineHeight: '15.18px',
  paddingBottom: '20px'
};

export const labelFileNameStyles: React.CSSProperties = {
  color: '#3D4047',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  lineHeight: '15.18px',
  paddingBottom: '20px'
};

export const newFilenameInputStyles: React.CSSProperties = {
  height: '36px',
  width: '520px',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: '450',
  color: '#000', // Ensures user-typed text is black
  fontFamily: 'Circular Std Book',
  border: '1px solid #76787F',
  padding: '5px 54px 5px 10px',
  outline: 'none'
};

export const extensionErrorStyles: React.CSSProperties = {
  color: 'red',
  fontSize: '12px',
  fontWeight: '450',
  fontFamily: 'Circular Std Book',
  marginTop: '5px'
};

export const dropdownWrapperStyles: React.CSSProperties = {
  position: 'relative',
  display: 'inline-block',
  marginTop: '10px'
};

export const actionsAutocompleteStyles: SxProps<Theme> = {
  height: '36px',
  width: '520px',
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    minHeight: '36px',
    border: '.5px solid #D9D9D9',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#D9D9D9'
    },
    '&.Mui-focused': {
      borderColor: '#D9D9D9'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  '& .MuiAutocomplete-listbox': {
    fontFamily: 'Circular Std Book !important'
  },
  '& .MuiInputBase-input': {
    fontSize: '14px'
  }
};

export const actionsAutocompleteLiStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  color: '#777777',
  fontWeight: 450
};

export const actionsAutocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const scrollBlockStyles: React.CSSProperties = {
  height: '300px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '520px'
};
