import React, { useEffect, useState } from 'react';

import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import {
  actionsOptionsParentStyles,
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsDropdownWrapperStyles,
  parentActionFirstOptionStyles,
  parentActionLabelStyles,
  parentActionRadioStyles,
  formControlStyles
} from './categoricalEncodingBody.styles';
import { ICategoricalEncodingBody } from './categoricalEncodingBody.models';
import { getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import { columnsAutocompleteStyles } from '../handleMissingValuesBody/handleMissingValuesBody.styles';

const CategoricalEncodingBody: React.FC<ICategoricalEncodingBody> = ({
  lessUniqueValues,
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [selectedParentAction, setSelectedParentAction] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );

  const allFieldsAreFilled = selectedColumns.length > 0 && selectedParentAction.length > 0;

  const handleColumnsChange = (_: any, newValue: Array<{ id: number; label: string }>) => {
    setSelectedColumns(newValue.map((option) => option.label));
  };
  const handleParentActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParentAction(event.target.value as 'ordinal' | 'one' | 'catboost');
  };
  const [isSelectedLessUniqueValuesColumns, setIsSelectedLessUniqueValuesColumns] = useState<boolean>(false);
  const [lessUniqueValuesElements, setLessUniqueValuesElements] = useState<[]>([]);
  const [lessUniqueValuesAsText, setLessUniqueValuesAsText] = useState<string>('');

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: selectedParentAction,
      subActionValue: '',
      outputColumnName: '',
      isCompleted: allFieldsAreFilled
    };
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    setIsSelectedLessUniqueValuesColumns(lessUniqueValues.some((item: any) => selectedColumns.includes(item)));
    setLessUniqueValuesElements(lessUniqueValues.filter((item: any) => selectedColumns.includes(item)));
    setLessUniqueValuesAsText((prev) => selectedColumns.filter((col) => lessUniqueValues.includes(col)).join(', '));
    if (selectedColumns.length === 0) {
      setSelectedParentAction('');
    }
  }, [selectedColumns, selectedParentAction]);

  return (
    <div style={bodyStyles}>
      <div style={columnsDropdownWrapperStyles}>
        <Autocomplete
          multiple
          value={dropdownOptions.filter((option: { id: number; label: string }) =>
            selectedColumns.includes(option.label)
          )}
          options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
          onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={columnsAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select columns"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedColumns([])
            }
          }}
        />
        {lessUniqueValuesElements.length > 0 && (
          <span style={columnsAutocompleteLiStyles}>
            The {lessUniqueValuesAsText} column{lessUniqueValuesElements.length > 1 ? 's' : ''} contain
            {lessUniqueValuesElements.length > 1 ? '' : 's'} more than 20 unique values, so the "one-hot encoding"
            method is not available.
          </span>
        )}
      </div>
      {selectedColumns.length > 0 && (
        <div>
          <RadioGroup
            value={selectedParentAction}
            onChange={handleParentActionChange}
            style={actionsOptionsParentStyles}
          >
            <FormControlLabel
              sx={formControlStyles}
              value="ordinal"
              control={<Radio sx={parentActionRadioStyles} />}
              label={
                <span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>ordinal encoding</span>
              }
            />
            {!isSelectedLessUniqueValuesColumns && (
              <FormControlLabel
                sx={formControlStyles}
                value="one-hot"
                control={<Radio sx={parentActionRadioStyles} />}
                label={
                  <span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>one-hot encoding</span>
                }
              />
            )}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default CategoricalEncodingBody;
