import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const wholeStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px'
};

export const spanStyles: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: '450',
  color: '#3D4047',
  fontFamily: 'Circular Std Book',
  marginBottom: '10px'
};

export const columnsAutocompleteStyles = (blocks: any): SxProps<Theme> => {
  return {
    minHeight: '36px',
    width: blocks.length > 1 ? '225px' : '250px',
    '& .MuiOutlinedInput-root': {
      padding: '0px',
      minHeight: '36px',
      border: '.5px solid #D9D9D9',
      borderRadius: '4px',
      '&:hover': {
        borderColor: '#D9D9D9'
      },
      '&.Mui-focused': {
        borderColor: '#D9D9D9'
      },
      '& fieldset': {
        border: 'none'
      }
    },
    '& .MuiAutocomplete-listbox': {
      fontFamily: 'Circular Std Book !important'
    },
    '& .MuiInputBase-input': {
      fontSize: '14px'
    }
  };
};

export const columnsAutocompleteLiStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  color: '#777777',
  fontWeight: 450
};

export const columnsAutocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const removeIconStyles = (blockIndex: number): React.CSSProperties => {
  return {
    color: 'red',
    alignItems: 'flex-start',
    padding: '20px 0 0 0',
    zIndex: '999999999'
  };
};
