import { IBlock } from '../processingPreprocess.models';

export const getActionsDropdownOptions = (
  blocks: IBlock[],
  block: IBlock,
  NADropdownOptions: { type: string; label: string }[] | null,
  onlyNumericDropdownOptions: { id: number; label: string }[] | null,
  onlyCategoricalDropdownOptions: { id: number; label: string }[] | null,
  allTypeDropdownOptions: { id: number; label: string }[] | null,
  onlyDateTimeDropdownOptions: { id: number; label: string }[] | null,
  dateTimeDropdownOptions: { id: number; label: string }[] | null
) => {
  const isActionUsedWithAllColumns = (actionLabel: string, options: { label: string }[] | null) => {
    if (!options || options.length === 0) return false;

    return options.every((option) =>
      blocks.some((b) => b.singleActionBody?.columns?.includes(option.label) && b.action === actionLabel)
    );
  };
  const allDateTimeColumnsUsed = onlyDateTimeDropdownOptions?.every((option) =>
    blocks.some(
      (b) =>
        b.action === 'Handle Datetime Columns' &&
        b.singleActionBody?.columns?.includes(option.label) &&
        dateTimeDropdownOptions?.every((dtOpt) =>
          blocks.some(
            (b2) =>
              b2.action === 'Handle Datetime Columns' &&
              b2.singleActionBody?.columns?.includes(option.label) &&
              b2.singleActionBody?.subAction === dtOpt.label
          )
        )
    )
  );

  return [
    ...(NADropdownOptions &&
    NADropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Handle Missing Values', NADropdownOptions)
      ? [{ id: 0, label: 'Handle Missing Values' }]
      : []),
    ...(onlyNumericDropdownOptions &&
    onlyNumericDropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Handle Outliers', onlyNumericDropdownOptions)
      ? [{ id: 1, label: 'Handle Outliers' }]
      : []),
    ...(onlyCategoricalDropdownOptions &&
    onlyCategoricalDropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Categorical Encoding', onlyCategoricalDropdownOptions)
      ? [{ id: 2, label: 'Categorical Encoding' }]
      : []),
    ...(onlyNumericDropdownOptions &&
    onlyNumericDropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Create New Columns', onlyNumericDropdownOptions)
      ? [{ id: 3, label: 'Create New Columns' }]
      : []),

    ...(onlyDateTimeDropdownOptions && onlyDateTimeDropdownOptions?.length > 0 && !allDateTimeColumnsUsed
      ? [{ id: 4, label: 'Handle Datetime Columns' }]
      : []),
    ...(onlyNumericDropdownOptions &&
    onlyNumericDropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Convert Numeric to Categorical', onlyNumericDropdownOptions)
      ? [{ id: 5, label: 'Convert Numeric to Categorical' }]
      : []),
    ...(allTypeDropdownOptions &&
    allTypeDropdownOptions?.length > 0 &&
    !isActionUsedWithAllColumns('Manage Columns', allTypeDropdownOptions)
      ? [{ id: 7, label: 'Manage Columns' }]
      : [])
  ];
};

// export const getActionsDropdownOptions = (
//   blocks: IBlock[],
//   block: IBlock,
//   NADropdownOptions: { type: string; label: string }[] | null,
//   onlyNumericDropdownOptions: { id: number; label: string }[] | null,
//   onlyCategoricalDropdownOptions: { id: number; label: string }[] | null,
//   allTypeDropdownOptions: { id: number; label: string }[] | null,
//   onlyDateTimeDropdownOptions: { id: number; label: string }[] | null,
//   dateTimeDropdownOptions: { id: number; label: string }[] | null
// ) => {
//   const allNAColumnsUsed = NADropdownOptions?.every((numOption) =>
//     blocks.some((b) => b.singleActionBody?.columns?.includes(numOption.label))
//   );
//   const allNumericColumnsUsed = onlyNumericDropdownOptions?.every((numOption) =>
//     blocks.some((b) => b.singleActionBody?.columns?.includes(numOption.label))
//   );
//   const allCategoricalColumnsUsed = onlyCategoricalDropdownOptions?.every((numOption) =>
//     blocks.some((b) => b.singleActionBody?.columns?.includes(numOption.label))
//   );
//   const allTypesColumnsUsed = allTypeDropdownOptions?.every((numOption) =>
//     blocks.some((b) => b.singleActionBody?.columns?.includes(numOption.label))
//   );
//   const allDateTimeColumnsUsed = onlyDateTimeDropdownOptions?.every((option) =>
//     blocks.some(
//       (b) =>
//         b.action === 'Handle Datetime Columns' &&
//         b.singleActionBody?.columns?.includes(option.label) &&
//         dateTimeDropdownOptions?.every((dtOpt) =>
//           blocks.some(
//             (b2) =>
//               b2.action === 'Handle Datetime Columns' &&
//               b2.singleActionBody?.columns?.includes(option.label) &&
//               b2.singleActionBody?.subAction === dtOpt.label
//           )
//         )
//     )
//   );
//
//   return [
//     ...(NADropdownOptions && NADropdownOptions?.length > 0 && !allNAColumnsUsed
//       ? [{ id: 0, label: 'Handle Missing Values' }]
//       : []),
//     ...(onlyNumericDropdownOptions && onlyNumericDropdownOptions?.length > 0 && !allNumericColumnsUsed
//       ? [{ id: 1, label: 'Handle Outliers' }]
//       : []),
//     ...(onlyCategoricalDropdownOptions && onlyCategoricalDropdownOptions?.length > 0 && !allCategoricalColumnsUsed
//       ? [{ id: 2, label: 'Categorical Encoding' }]
//       : []),
//     ...(onlyNumericDropdownOptions && onlyNumericDropdownOptions?.length > 0 && !allNumericColumnsUsed
//       ? [{ id: 3, label: 'Create New Columns' }]
//       : []),
//
//     ...(onlyDateTimeDropdownOptions && onlyDateTimeDropdownOptions?.length > 0 && !allDateTimeColumnsUsed
//       ? [{ id: 4, label: 'Handle Datetime Columns' }]
//       : []),
//     ...(onlyNumericDropdownOptions && onlyNumericDropdownOptions?.length > 0 && !allNumericColumnsUsed
//       ? [{ id: 5, label: 'Convert Numeric to Categorical' }]
//       : []),
//     ...(allTypeDropdownOptions && allTypeDropdownOptions?.length > 0 && !allTypesColumnsUsed
//       ? [{ id: 7, label: 'Manage Columns' }]
//       : [])
//   ];
// };

export const getFilteredDropdownOptions = (
  dropdownOptions: ({ id: number; label: string } | { type: string; label: string })[] | null,
  blocks: IBlock[],
  block: IBlock
) => {
  if (!Array.isArray(dropdownOptions)) return []; // Ensure dropdownOptions is an array
  if (!Array.isArray(blocks)) return []; // Ensure blocks is an array
  return dropdownOptions?.filter((option) => {
    // Check if this column is used in ANY 'Manage Columns' action with 'replace' or 'rename'
    const isColumnManaged = blocks.some(
      (b) =>
        b.action === 'Manage Columns' &&
        ['drop', 'rename'].includes(b.singleActionBody?.subAction) &&
        b.singleActionBody?.columns?.includes(option.label)
    );

    const isColumnDateTime = blocks.some(
      (b) =>
        b.action === 'Handle Datetime Columns' &&
        b?.action === block?.action &&
        b.singleActionBody?.columns?.includes(option.label) &&
        b.singleActionBody?.subAction?.includes(option.label)
    );

    if (isColumnDateTime) {
      return false;
    }

    if (isColumnManaged) {
      return false;
    }

    if (isColumnDateTime) {
      return false;
    }

    // Check if this column is used in ANY block with the SAME action
    const isColumnUsedInSameAction = blocks.some(
      (b) => b?.action === block?.action && b.singleActionBody?.columns?.includes(option.label)
    );

    // Check if this column is used in ANY block with a DIFFERENT action
    const isColumnUsedInOtherAction = blocks.some(
      (b) => b?.action !== block?.action && b.singleActionBody?.columns?.includes(option.label)
    );

    const lastActionWasSame = blocks.length > 0 && blocks[blocks.length - 1]?.action === block.action;

    if (isColumnUsedInSameAction) {
      return false;
    }

    // Exclude the column if:
    // 1. It is already used in the same action type
    // 2. OR it is used in a 'Manage Columns' action with 'replace' or 'rename'
    return !(isColumnUsedInSameAction && lastActionWasSame);
  });
};

export const actionsDropdownOptions = [
  { id: 0, label: 'Handle missing values' },
  { id: 1, label: 'Handle Outliers' },
  { id: 2, label: 'Categorical encoding' },
  { id: 3, label: 'Create new columns' },
  { id: 4, label: 'Manage columns' },
  { id: 5, label: 'Handle datetime columns' },
  { id: 6, label: 'Converting Numeric to Categorical' }
];
