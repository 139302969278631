import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const blockContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 30px',
  width: '540px'
};
export const blockWholeStyles: React.CSSProperties = {
  borderTop: '1px solid #ccc',
  borderBottom: '1px solid #ccc',
  width: '540px'
};

export const blockWrapperStyles = (openAccordionIndex: number): React.CSSProperties => {
  return {
    height: '38px',
    backgroundColor: '#fff',
    padding: openAccordionIndex === 0 ? '10px 10px 0 10px' : '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };
};

export const stepTitleStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450',
  display: 'table',
  margin: '0 auto',
  color: '#3D4047'
};

export const blockDetailsStyles: React.CSSProperties = {
  padding: '10px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const dropdownWrapperStyles: React.CSSProperties = {
  position: 'relative',
  display: 'inline-block',
  width: '100%'
};

export const removeIconStyles = (blockIndex: number): React.CSSProperties => {
  return {
    color: 'red',
    visibility: blockIndex > 0 ? 'visible' : 'hidden',
    alignItems: 'flex-start',
    padding: '0'
  };
};

export const actionsAutocompleteLiStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  color: '#777777',
  fontWeight: 450
};

export const actionsAutocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const actionsAutocompleteStyles: SxProps<Theme> = {
  height: '36px',
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    minHeight: '36px',
    border: '.5px solid #D9D9D9',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#D9D9D9'
    },
    '&.Mui-focused': {
      borderColor: '#D9D9D9'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  // '& .MuiSvgIcon-root': {
  //   width: '24px',
  //   height: '24px',
  // },
  '& .MuiAutocomplete-listbox': {
    fontFamily: 'Circular Std Book !important'
  },
  '& .MuiInputBase-input': {
    fontSize: '14px'
  }
};
