import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const bodyStyles: React.CSSProperties = {
  width: '100%',
  marginTop: '10px'
};

export const columnsDropdownWrapperStyles: React.CSSProperties = {
  marginBottom: '10px'
};

export const columnsAutocompleteStyles: SxProps<Theme> = {
  minHeight: '36px',
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    minHeight: '36px',
    border: '.5px solid #D9D9D9',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#D9D9D9'
    },
    '&.Mui-focused': {
      borderColor: '#D9D9D9'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  '& .MuiAutocomplete-listbox': {
    fontFamily: 'Circular Std Book !important'
  },
  '& .MuiInputBase-input': {
    fontSize: '14px'
  }
};

export const columnsAutocompleteLiStyles: React.CSSProperties = {
  fontFamily: 'Circular Std Book',
  fontSize: '12px',
  color: '#777777',
  fontWeight: 450
};

export const columnsAutocompletePlaceholderStyles: SxProps<Theme> = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    color: '#777777',
    fontWeight: 450
  }
};

export const actionsOptionsParentStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  marginBottom: '10px'
};

export const parentActionFirstOptionStyles: React.CSSProperties = {
  marginRight: '20px'
};

export const parentActionRadioStyles: SxProps<Theme> = {
  padding: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '16px'
  },
  '&.Mui-checked': {
    color: '#000'
  },
  color: '#000'
};

export const parentActionLabelStyles: React.CSSProperties = {
  fontSize: '12px',
  marginLeft: '6px',
  fontFamily: 'Circular Std Book',
  fontWeight: '450',
  color: '#333333'
};

export const formControlStyles: SxProps<Theme> = {
  marginLeft: '0',
  marginRight: '0'
};
