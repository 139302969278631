import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.uploadStatus;

const dataProcessingStatusData = createSelector([selector], (state) => state);

export default {
  dataProcessingStatusData
};
