export interface IBlock {
  id: number;
  action: string;
  singleActionBody: any;
}

export const emptyBlock = (block?: Partial<IBlock>): IBlock => ({
  id: 0,
  action: '',
  singleActionBody: {},
  ...block
});
