import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  titleStyles,
  closeIconStyles,
  titleBoxStyles,
  buttonsStyles,
  discardButtonStyles,
  saveButtonStyles,
  boxStyles,
  middleBlockStyles
} from './processingMerge.styles';
import { IProcessingMerge } from './processingMerge.models';
import ProcessingMergeBody from './processingMergeBody/processingMergeBody.component';

const ProcessingMerge: React.FC<IProcessingMerge> = ({
  processingMergeJoinBlocks,
  setProcessingMergeJoinBlocks,
  processingMergeNewFileNameValue,
  setProcessingMergeNewFileNameValue,
  processingMergeUploadStatus,
  setProcessingMergeUploadStatus,
  processingMergeUploadProgress,
  setProcessingMergeUploadProgress,
  processingMergeUploadSucceeded,
  setProcessingMergeUploadSucceeded,
  open,
  onClose,
  summaryData,
  processingMergeInputData,
  setProcessingMergeInputData,
  processingMergeActiveStep,
  setProcessingMergeActiveStep
}) => {
  const isDisabled = true;

  const handleDiscard = () => {};

  const handleSave = async () => {};

  return (
    <>
      <Box sx={boxStyles(open)}>
        <Box sx={titleBoxStyles}>
          <span style={titleStyles}>Merge datasets</span>
          <IconButton onClick={onClose} sx={closeIconStyles}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div style={middleBlockStyles}>
          <ProcessingMergeBody
            processingMergeJoinBlocks={processingMergeJoinBlocks}
            setProcessingMergeJoinBlocks={setProcessingMergeJoinBlocks}
            summaryData={summaryData}
            processingMergeInputData={processingMergeInputData}
            setProcessingMergeInputData={setProcessingMergeInputData}
            processingMergeActiveStep={processingMergeActiveStep}
            setProcessingMergeActiveStep={setProcessingMergeActiveStep}
            processingMergeUploadStatus={processingMergeUploadStatus}
            setProcessingMergeUploadStatus={setProcessingMergeUploadStatus}
            processingMergeUploadProgress={processingMergeUploadProgress}
            setProcessingMergeUploadProgress={setProcessingMergeUploadProgress}
            processingMergeUploadSucceeded={processingMergeUploadSucceeded}
            setProcessingMergeUploadSucceeded={setProcessingMergeUploadSucceeded}
            processingMergeNewFileNameValue={processingMergeNewFileNameValue}
            setProcessingMergeNewFileNameValue={setProcessingMergeNewFileNameValue}
          />
        </div>
        <div style={buttonsStyles}>
          <button style={discardButtonStyles} onClick={handleDiscard}>
            Discard
          </button>
          <button onClick={handleSave} style={saveButtonStyles(isDisabled)} disabled={!isDisabled}>
            Save
          </button>
        </div>
      </Box>
      {/*<ProcessingSaveModal*/}
      {/*  open={modalOpen}*/}
      {/*  onClose={handleCloseModal}*/}
      {/*  uploadStatus={saveStatus}*/}
      {/*  setUploadStatus={setSaveStatus}*/}
      {/*  progress={saveProgress}*/}
      {/*  setProgress={setSaveProgress}*/}
      {/*  onContinue={handleCloseModal}*/}
      {/*  fileName={newFileNameValue.length > 0 ? newFileNameValue : summaryData?.filename}*/}
      {/*  fileSize={summaryData.file_size}*/}
      {/*/>*/}
    </>
  );
};

export default ProcessingMerge;
