import React from 'react';

export const startFromRangeStyles: React.CSSProperties = {};
export const endRangeStyles: React.CSSProperties = {};

export const optionalNameStyles: React.CSSProperties = {};

export const columnNameInputStyles: React.CSSProperties = {
  width: '150px',
  height: '36px',
  border: '.5px solid #D9D9D9',
  borderRadius: '4px',
  color: '#000',
  fontSize: '14px',
  fontWeight: '450',
  fontFamily: 'Circular Std Book',
  paddingLeft: '10px',
  outline: 'none'
};

export const removeIconStyles = (blockIndex: number): React.CSSProperties => {
  return {
    color: 'red',
    alignItems: 'flex-start',
    padding: '0',
    zIndex: '999999999'
  };
};

export const blockWrapperStyles: React.CSSProperties = {
  display: 'flex',
  marginBottom: '10px',
  justifyContent: 'space-between',
  alignItems: 'center'
};
