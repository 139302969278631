import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { DataProcessingProps } from '../../../types/reduxTypes/dataProcessing';

const createReducer = <T extends SliceCaseReducers<DataProcessingProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setDataProcessingData(state, action) {
    state.dataProcessingData = action.payload;
  },
  setDataProcessingStatus(state, action) {
    state.dataProcessingStatus = action.payload;
  }
});

export default reducers;
