import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const sidebarWidth = '600px';

export const boxStyles = (open: boolean): SxProps<Theme> => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'fixed',
    borderRadius: '7px',
    top: 0,
    right: 0,
    width: sidebarWidth,
    backgroundColor: '#FFF',
    boxShadow: '-2px 0px 8px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    padding: '0',
    overflowY: 'auto',
    transition: 'transform 1s ease-in-out',
    transform: `translateX(${open ? '0' : `calc(100% + ${sidebarWidth})`})`
  };
};

export const titleBoxStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  flex: '0 0 auto'
};

export const titleStyles: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: '700',
  color: '#3D4047',
  paddingTop: '20px',
  paddingLeft: '20px',
  fontFamily: 'Circular Std Book'
};

export const closeIconStyles: SxProps<Theme> = {
  position: 'absolute',
  width: '19px',
  height: '19px',
  color: '#76787F',
  right: '19px',
  top: '19px'
};

export const borderBottomStyles: React.CSSProperties = {
  margin: '0px 30px',
  borderBottom: '1px solid #B3B5B9'
};

export const wholeNewFileStyles: React.CSSProperties = { marginTop: '11px' };

export const newFileCheckboxLabelStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '40px'
};

export const newFileLabelStyles: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: '450',
  color: '#777777',
  paddingTop: '1px',
  fontFamily: 'Circular Std Book'
};

export const newFileCheckboxStyles: React.CSSProperties = { marginRight: '10px' };

export const newFilenameInputStyles: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: '450',
  color: '#000', // Ensures user-typed text is black
  fontFamily: 'Circular Std Book',
  border: '1px solid #76787F',
  borderRadius: '4px',
  marginLeft: '66px',
  marginTop: '5px',
  width: '280px',
  height: '24px',
  padding: '5px 54px 5px 10px',
  outline: 'none',

  // Define a CSS variable for placeholder color
  '--placeholder-color': '#777'
} as React.CSSProperties;

export const extensionErrorStyles: React.CSSProperties = {
  color: 'red',
  fontSize: '12px',
  fontWeight: '450',
  fontFamily: 'Circular Std Book',
  marginLeft: '66px',
  marginTop: '5px'
};

export const scrollBlockStyles: React.CSSProperties = {
  height: '150px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  flex: '1'
};
export const blockStyles: React.CSSProperties = {
  paddingBottom: '20px'
};

export const buttonsStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: '0 0 auto',
  marginBottom: '20px'
};

export const discardButtonStyles: React.CSSProperties = {
  width: '180px',
  height: '36px',
  backgroundColor: '#EEEFF1',
  color: '#000000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontWeight: '450px',
  borderRadius: '8px',
  marginRight: '28px',
  border: 'none',
  cursor: 'pointer'
};
export const saveButtonStyles = (isDisabled: boolean): React.CSSProperties => {
  return {
    width: '180px',
    height: '36px',
    backgroundColor: !isDisabled ? '#B3B5B9' : '#334063',
    color: '#ffffff',
    fontFamily: 'Circular Std Book',
    fontSize: '16px',
    fontWeight: '450px',
    borderRadius: '8px',
    border: 'none',
    cursor: !isDisabled ? 'default' : 'pointer'
  };
};
