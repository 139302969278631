import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const dataProcessingManager = {
  dataProcessing(params: any, url: any) {
    return axiosInstance.v2({
      url: url,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default dataProcessingManager;
