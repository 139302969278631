import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { sidebarWidth } from '../processingPreprocess/processingPreprocess.styles';

export const titleBoxStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  flex: '0 0 auto'
};

export const titleStyles: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: '700',
  color: '#3D4047',
  paddingTop: '20px',
  paddingLeft: '20px',
  fontFamily: 'Circular Std Book'
};

export const closeIconStyles: SxProps<Theme> = {
  position: 'absolute',
  width: '19px',
  height: '19px',
  color: '#76787F',
  right: '19px',
  top: '19px'
};

export const middleBlockStyles: React.CSSProperties = {
  height: '150px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  flex: '1'
};

export const buttonsStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: '0 0 auto',
  marginBottom: '20px'
};

export const discardButtonStyles: React.CSSProperties = {
  width: '180px',
  height: '36px',
  backgroundColor: '#EEEFF1',
  color: '#000000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontWeight: '450px',
  borderRadius: '8px',
  marginRight: '28px',
  border: 'none',
  cursor: 'pointer'
};

export const saveButtonStyles = (isDisabled: boolean): React.CSSProperties => {
  return {
    width: '180px',
    height: '36px',
    backgroundColor: !isDisabled ? '#B3B5B9' : '#334063',
    color: '#ffffff',
    fontFamily: 'Circular Std Book',
    fontSize: '16px',
    fontWeight: '450px',
    borderRadius: '8px',
    border: 'none',
    cursor: !isDisabled ? 'default' : 'pointer'
  };
};

export const boxStyles = (open: boolean): SxProps<Theme> => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'fixed',
    borderRadius: '7px',
    top: 0,
    right: 0,
    width: sidebarWidth,
    backgroundColor: '#FFF',
    boxShadow: '-2px 0px 8px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    padding: '0',
    overflowY: 'auto',
    transition: 'transform 1s ease-in-out',
    transform: `translateX(${open ? '0' : `calc(100% + ${sidebarWidth})`})`
  };
};
