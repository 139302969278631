import React, { useEffect, useState } from 'react';

import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { actionsDropdownOptions, getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import {
  actionsOptionsParentStyles,
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsDropdownWrapperStyles,
  formControlStyles,
  parentActionFirstOptionStyles,
  parentActionLabelStyles,
  parentActionRadioStyles,
  renameInputStyles
} from './manageColumnsBody.styles';
import {
  columnsAutocompleteStyles,
  customValueInputStyles
} from '../handleMissingValuesBody/handleMissingValuesBody.styles';
import { IManageColumnsBody } from './manageColumnsBody.models';
import { Simulate } from 'react-dom/test-utils';
import drop = Simulate.drop;

const ManageColumnsBody: React.FC<IManageColumnsBody> = ({
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [selectedParentAction, setSelectedParentAction] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );
  const [renameInputValue, setRenameInputValue] = useState<string>(
    block.singleActionBody.outputColumnName?.length > 0 ? block.singleActionBody.outputColumnName : ''
  );

  const allFieldsAreFilled =
    selectedColumns.length > 0 &&
    (selectedParentAction === 'drop' || (selectedParentAction === 'rename' && renameInputValue.length > 0));

  const handleColumnsChange = (_: any, newValue: Array<{ id: number; label: string }>) => {
    setSelectedColumns(newValue.map((option) => option.label));
  };

  const handleParentActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParentAction(event.target.value as 'drop' | 'rename');
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: selectedParentAction,
      subActionValue: '',
      outputColumnName: renameInputValue,
      isCompleted: allFieldsAreFilled
    };
    if (selectedParentAction !== 'rename') {
      setRenameInputValue('');
    }
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    if (selectedColumns.length === 0) {
      setSelectedParentAction('');
      setRenameInputValue('');
    }
  }, [selectedColumns, selectedParentAction, renameInputValue]);

  return (
    <div style={bodyStyles}>
      <div style={columnsDropdownWrapperStyles}>
        <Autocomplete
          multiple
          value={dropdownOptions.filter((option: { id: number; label: string }) =>
            selectedColumns.includes(option.label)
          )}
          options={getFilteredDropdownOptions(dropdownOptions, blocks, block)} // Filter out selected options
          onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={columnsAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select columns"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedColumns([])
            }
          }}
        />
      </div>
      {selectedColumns.length > 0 && (
        <div>
          <RadioGroup
            value={selectedParentAction}
            onChange={handleParentActionChange}
            style={actionsOptionsParentStyles}
          >
            <FormControlLabel
              sx={formControlStyles}
              value="drop"
              control={<Radio sx={parentActionRadioStyles} />}
              label={<span style={{ ...parentActionLabelStyles, ...parentActionFirstOptionStyles }}>drop</span>}
            />
            {selectedColumns.length === 1 && (
              <FormControlLabel
                sx={formControlStyles}
                value="rename"
                control={<Radio sx={parentActionRadioStyles} />}
                label={<span style={parentActionLabelStyles}>rename</span>}
              />
            )}
            {selectedParentAction === 'rename' && (
              <input
                value={renameInputValue}
                onChange={(e) => setRenameInputValue(e.target.value.slice(0, 40))}
                style={renameInputStyles}
              />
            )}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default ManageColumnsBody;
