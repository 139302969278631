import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';

export const modalStyles: React.CSSProperties = {
  padding: '0',
  width: '600px'
};

export const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const closeIconStyles: SxProps<Theme> = {
  position: 'absolute',
  right: '18px',
  top: '18px',
  height: '19px',
  width: '19px',
  color: '#76787F'
};

export const titleStyles: React.CSSProperties = {
  padding: '0',
  marginTop: '30px',
  marginBottom: '40px',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '450',
  fontSize: '20px',
  fontFamily: 'Circular Std Book',
  color: '#334063'
};

export const fileNameStyles: React.CSSProperties = {
  marginBottom: '2px',
  display: 'flex',
  color: '#3D4047',
  fontWeight: '450',
  fontSize: '16px',
  fontFamily: 'Circular Std Book'
};

export const fileSizeStyles: React.CSSProperties = {
  marginBottom: '15px',
  color: '#76787F',
  fontWeight: '450',
  fontSize: '12px',
  fontFamily: 'Circular Std Book'
};

export const progressStyles: SxProps<Theme> = {
  padding: '0',
  marginBottom: '61px',
  textAlign: 'center',
  height: '20px',
  width: '320px',
  overflow: 'hidden'
};

export const progressBar1Styles: React.CSSProperties = {
  width: '100%',
  height: '8px',
  backgroundColor: '#e0e0e0',
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'relative'
};

export const buttonsStyles: SxProps<Theme> = {
  marginBottom: '32px'
};

export const cancelButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '35px',
  marginRight: '20px',
  border: '1px solid #334063',
  borderRadius: '8px',
  backgroundColor: '#fff',
  color: '#334063',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450px',
  boxShadow: 'none'
};

export const continueButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '35px',
  borderRadius: '8px',
  backgroundColor: '#334063',
  color: '#fff',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450px'
};
export const continueButtonDisabledStyles: React.CSSProperties = {
  width: '120px',
  height: '35px',
  backgroundColor: '#B3B5B9',
  color: '#fff',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontWeight: '450px',
  border: '1px solid #B3B5B9',
  borderRadius: '8px'
};
