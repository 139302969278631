import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { getFilteredDropdownOptions } from '../../processingPreprocessInnerBlock.constants';
import {
  bodyStyles,
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsAutocompleteStyles,
  columnsDropdownWrapperStyles,
  dropdownWrapperStyles,
  dateTimeAutocompleteStyles,
  dateTimeAutocompleteLiStyles,
  dateTimeAutocompletePlaceholderStyles,
  dateTimeInputStyles,
  childWrapperStyles
} from './dateTimeColumnsBody.styles';
import { dateTimeDropdownOptions } from './dateTimeColumnsBody.constants';
import { IDateTimeColumnsBody } from './dateTimeColumnsBody.models';

const DateTimeColumnsBody: React.FC<IDateTimeColumnsBody> = ({
  dropdownOptions,
  block,
  setBlocks,
  updateSingleActionBody,
  blocks
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    block.singleActionBody.columns?.length > 0 ? block.singleActionBody.columns : []
  );
  const [selectedDateTimeOption, setSelectedDateTimeOption] = useState<string>(
    block.singleActionBody.subAction?.length > 0 ? block.singleActionBody.subAction : ''
  );
  const [dateTimeInputValue, setDateTimeInputValue] = useState<string>(
    block.singleActionBody.subActionName?.length > 0 ? block.singleActionBody.subActionName : ''
  );

  const allFieldsAreFilled =
    selectedColumns.length > 0 && selectedDateTimeOption.length > 0 && dateTimeInputValue.length > 0;

  const handleColumnsChange = (_: any, newValue: { id: number; label: string } | null) => {
    setSelectedColumns(newValue ? [newValue.label] : []);
  };

  const handleDateTimeDropdownChange = (_: React.SyntheticEvent, newValue: { id: number; label: string } | null) => {
    setSelectedDateTimeOption(newValue ? newValue.label : '');
  };

  useEffect(() => {
    const newActionBody = {
      columns: selectedColumns,
      subAction: selectedDateTimeOption,
      subActionValue: '',
      outputColumnName: dateTimeInputValue,
      isCompleted: allFieldsAreFilled
    };
    const updatedBlocks = updateSingleActionBody(blocks, block.id, newActionBody);
    setBlocks(updatedBlocks);
    if (selectedColumns.length === 0) {
      setSelectedDateTimeOption('');
      setDateTimeInputValue('');
    }
  }, [selectedColumns, selectedDateTimeOption, dateTimeInputValue]);

  return (
    <div style={bodyStyles}>
      <div style={columnsDropdownWrapperStyles}>
        <Autocomplete
          value={dropdownOptions.find((option: any) => option.label === selectedColumns[0]) || null}
          options={dropdownOptions.filter((option: any) => {
            // Get all used subActions for this column
            const usedSubActions = blocks
              .filter(
                (b) => b.action === 'Handle Datetime Columns' && b.singleActionBody?.columns?.includes(option.label)
              )
              .map((b) => b.singleActionBody?.subAction);

            const allDateTimeOptions = dateTimeDropdownOptions.map((opt) => opt.label);

            // If all datetime subActions are used, exclude this column
            return !allDateTimeOptions.every((opt) => usedSubActions.includes(opt));
          })}
          onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles}
          renderOption={(props, option) => (
            <li {...props} style={columnsAutocompleteLiStyles}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select column"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          componentsProps={{
            clearIndicator: {
              onClick: () => setSelectedColumns([])
            }
          }}
        />
      </div>
      {selectedColumns.length > 0 && (
        <div style={childWrapperStyles}>
          <div style={dropdownWrapperStyles}>
            <Autocomplete
              value={dateTimeDropdownOptions.find((option) => option.label === selectedDateTimeOption) || null}
              options={dateTimeDropdownOptions.filter(
                (opt) =>
                  !blocks.some(
                    (b) =>
                      b.singleActionBody?.columns?.includes(selectedColumns[0]) &&
                      b.singleActionBody?.subAction === opt.label
                  )
              )}
              onChange={handleDateTimeDropdownChange}
              sx={dateTimeAutocompleteStyles}
              renderOption={(props, option) => (
                <li {...props} style={dateTimeAutocompleteLiStyles}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="extract column"
                  label=""
                  InputProps={{
                    ...params.InputProps,
                    style: dateTimeAutocompleteLiStyles
                  }}
                  sx={dateTimeAutocompletePlaceholderStyles}
                />
              )}
              componentsProps={{
                clearIndicator: {
                  onClick: () => setSelectedDateTimeOption('')
                }
              }}
              disableClearable={selectedDateTimeOption.length === 0}
            />
          </div>
          <div>
            <input
              value={dateTimeInputValue}
              onChange={(e) => setDateTimeInputValue(e.target.value.slice(0, 40))}
              placeholder="Output column"
              style={dateTimeInputStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default DateTimeColumnsBody;
