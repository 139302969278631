import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  blockStyles,
  chartStyles,
  numberStyles,
  sidebarWidth,
  textStyles,
  titleStyles,
  wholeInfoStyles,
  tabsParentStyles,
  tabsContentStyles,
  blockLastStyles,
  closeIconStyles,
  titleBoxStyles,
  chartTitleStyles,
  borderBottomStyles,
  descriptionStyles
} from './processingTableSummary.styles';
import { IProcessingTableSummary } from './processingTableSummary.models';
import ProcessingProgressBar from '../processingProgressBar/processingProgressBar.component';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import ProcessingColoredHistogram from '../processingColoredHistogram/processingColoredHistogram.component';
import ProcessingBiasReport from '../processingBiasReport/processingBiasReport.component';
import ProcessingChartBar from '../processingChartBar/processingChartBar.component';

const ProcessingTableSummary: React.FC<IProcessingTableSummary> = ({
  showAll,
  setShowAll,
  shapeData,
  summaryData,
  activeTab,
  setActiveTab,
  histogramChartBarData,
  defaultChartBarData,
  biasReportChartData,
  triangularChartBarData,
  numericCorrelationsData,
  headerCategoryData,
  biasReportCategoricalsData,
  open,
  onClose,
  columnName,
  modelType,
  label
}) => {
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const tabButtonStyles = (tab: string): React.CSSProperties => ({
    padding: '4px 17px',
    marginRight: '20px',
    fontWeight: '450',
    fontSize: '14px',
    fontFamily: 'Circular Std Book',
    border: '1px solid #334063',
    borderRadius: '4px',
    backgroundColor: activeTab === tab ? '#B0BED8' : '#fff',
    color: '#334063',
    cursor: 'pointer'
  });

  const boxStyles: SxProps<Theme> = {
    position: 'fixed',
    border: '1px solid grey',
    borderRadius: '7px',
    top: 0,
    right: 0,
    width: sidebarWidth,
    height: '100vh',
    backgroundColor: 'white',
    boxShadow: 3,
    zIndex: 1000,
    padding: '0',
    overflowY: 'scroll', // Ensures scrolling
    scrollbarWidth: 'none', // Hides scrollbar in Firefox
    transition: 'transform 1s ease-in-out',
    transform: `translateX(${open ? '0' : `calc(100% + ${sidebarWidth})`})`,
    '&::-webkit-scrollbar': {
      display: 'none' // Hides scrollbar in Chrome, Safari, and Edge
    }
  };

  useEffect(() => {
    if (
      !(
        (Object.keys(triangularChartBarData).length > 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
        (Object.keys(triangularChartBarData).length < 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
        modelType !== 'object'
      )
    ) {
      setActiveTab('detailedDistribution');
    }
    if (
      !(
        (Object.keys(triangularChartBarData).length > 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
        (Object.keys(triangularChartBarData).length < 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
        modelType !== 'object'
      ) &&
      modelType === 'object'
    ) {
      setActiveTab('biasReport');
    }
  }, []);

  useEffect(() => {
    if (
      (Object.keys(triangularChartBarData).length > 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
      (Object.keys(triangularChartBarData).length < 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
      modelType !== 'object'
    ) {
      setActiveTab('correlations');
    }
    setShowAll(false);
  }, [columnName]);

  return (
    <Box sx={{ ...boxStyles }}>
      <Box sx={titleBoxStyles}>
        <span style={titleStyles}>{columnName}</span>
        <IconButton onClick={onClose} sx={closeIconStyles}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div style={descriptionStyles}>
        {shapeData?.rows > 50000 && (
          <span>The distributions and other statistics are derived from a sample of the dataset.</span>
        )}
      </div>
      <div style={wholeInfoStyles}>
        <div style={blockStyles}>
          <span style={textStyles}>Rows</span>
          <span style={numberStyles}>{shapeData?.rows}</span>
        </div>
        <div style={blockStyles}>
          <span style={textStyles}>Empty Rows</span>
          <span style={numberStyles}>
            {summaryData?.find((item: any) => item.column_name === columnName)?.na_values}
          </span>
        </div>
        <div style={blockLastStyles}>
          <span style={textStyles}>Unique Values</span>
          <span style={numberStyles}>
            {summaryData?.find((item: any) => item.column_name === columnName)?.unique_values_count > 0
              ? parseInt(summaryData?.find((item: any) => item.column_name === columnName)?.unique_values_count)
              : summaryData?.find((item: any) => item.column_name === columnName)?.unique_values_count}
          </span>
        </div>
      </div>
      <div style={chartTitleStyles}>
        <span>Distribution</span>
      </div>
      <div style={chartStyles}>
        {modelType === 'object' ? (
          (() => {
            const categoryData = Object.entries((headerCategoryData as Record<string, any>) || {});
            const remaining = categoryData.slice(3);
            const firstThree = remaining.length > 1 ? categoryData.slice(0, 3) : categoryData.slice(0, 4);
            const othersPercentage = remaining.reduce((sum, [, value]) => sum + value.percentage, 0);
            const othersCount = remaining.reduce((sum, [, value]) => sum + value.count, 0);

            return (
              <>
                <style>
                  {`
        .progress-bar-others {
          opacity: 0.6;
        }
      `}
                </style>
                {showAll ? (
                  categoryData.map(([key, value]) => (
                    <ProcessingProgressBar
                      key={key}
                      percentage={parseFloat((value.percentage * 100).toFixed(3))}
                      counts={value.count}
                      name={key}
                      mode={false}
                      bsCat={false}
                    />
                  ))
                ) : (
                  <>
                    {firstThree.map(([key, value]) => (
                      <ProcessingProgressBar
                        key={key}
                        percentage={parseFloat((value.percentage * 100).toFixed(3))}
                        counts={value.count}
                        name={key}
                        mode={false}
                        bsCat={false}
                      />
                    ))}
                    {remaining.length > 1 && (
                      <div
                        style={{
                          opacity: 0.6
                        }}
                      >
                        <ProcessingProgressBar
                          key="others"
                          percentage={parseFloat((othersPercentage * 100).toFixed(3))}
                          counts={othersCount}
                          name="Others"
                          mode={false}
                          bsCat={false}
                        />
                      </div>
                    )}
                  </>
                )}
                {remaining.length > 1 && (
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '10px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#007BFF'
                    }}
                    onClick={toggleShowAll}
                  >
                    {showAll ? 'Show Less' : 'Show More'}
                  </div>
                )}
              </>
            );
          })()
        ) : (
          <ProcessingChartBar defaultChartBarData={defaultChartBarData} label="" open={true} />
        )}
      </div>

      <div style={borderBottomStyles}></div>
      <div style={tabsParentStyles}>
        {((Object.keys(triangularChartBarData).length > 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
          (Object.keys(triangularChartBarData).length < 11 && triangularChartBarData.hasOwnProperty(columnName)) ||
          modelType !== 'object') && (
          <button onClick={() => setActiveTab('correlations')} style={tabButtonStyles('correlations')}>
            Correlations
          </button>
        )}
        {modelType !== 'object' && (
          <button onClick={() => setActiveTab('detailedDistribution')} style={tabButtonStyles('detailedDistribution')}>
            Detailed Distribution
          </button>
        )}
        {((modelType !== 'object' &&
          biasReportChartData['numerics']?.[columnName] &&
          Object.keys(biasReportChartData['numerics'][columnName]).length > 0) ||
          (modelType === 'object' &&
            biasReportChartData['categoricals']?.[columnName] &&
            Object.keys(biasReportChartData['categoricals'][columnName]).length > 0)) && (
          <button onClick={() => setActiveTab('biasReport')} style={tabButtonStyles('biasReport')}>
            Bias Report
          </button>
        )}
      </div>
      <div style={tabsContentStyles} id="plot-dom">
        {activeTab === 'correlations' && (
          <>
            {(() => {
              if (modelType === 'object') {
                if (triangularChartBarData.hasOwnProperty(columnName)) {
                  if (Object.keys(triangularChartBarData).length < 11) {
                    return (
                      <>
                        <div style={descriptionStyles}>
                          The Correlations between categorical columns are computed using <b>Chi-Square</b> Test of
                          Independence․ A p-value less than 0.05 indicates a statistically significant association
                          between the two categorical variables.
                        </div>
                        <ProcessingChartBar
                          modelType={modelType}
                          activeTab={activeTab}
                          triangularChartBarData={triangularChartBarData}
                          label=""
                          open={true}
                        />
                      </>
                    );
                  } else {
                    return (
                      <>
                        {Object.keys(triangularChartBarData) && triangularChartBarData[columnName] && (
                          <div style={descriptionStyles}>
                            <span>
                              The Correlations between categorical columns are computed using <b>Chi-Square</b> Test of
                              Independence․ A p-value less than 0.05 indicates a statistically significant association
                              between the two categorical variables.
                            </span>
                          </div>
                        )}
                        {Object.keys(triangularChartBarData) &&
                          triangularChartBarData[columnName] &&
                          Object.entries(triangularChartBarData[columnName] as Record<string, number>).map(
                            ([key, value]) => (
                              <>
                                <ProcessingProgressBar
                                  key={key}
                                  percentage={parseFloat(value.toFixed(3))}
                                  name={key}
                                  mode={true}
                                  bsCat={false}
                                  categoricalCorrelation={true}
                                />
                              </>
                            )
                          )}
                      </>
                    );
                  }
                }
              } else {
                return (
                  <>
                    <div style={descriptionStyles}>
                      <span>
                        The Correlations between numeric columns are computed using <b>Pearson's</b> correlation
                        coefficients.
                      </span>
                    </div>
                    {numericCorrelationsData &&
                      Object.entries(numericCorrelationsData as Record<string, number>).map(([key, value]) => (
                        <ProcessingProgressBar
                          key={key}
                          percentage={parseFloat((value * 100).toFixed(1))}
                          name={key}
                          mode={true}
                          bsCat={false}
                        />
                      ))}
                  </>
                );
              }
            })()}
          </>
        )}

        {activeTab === 'detailedDistribution' && (
          <>
            {modelType !== 'object' && (
              <>
                <div style={descriptionStyles}>
                  <span>Select a categorical variable to color the histogram based on its distinct categories.</span>
                </div>
                <ProcessingColoredHistogram
                  columnName={columnName}
                  defaultChartBarData={defaultChartBarData}
                  histogramChartBarData={histogramChartBarData}
                />
              </>
            )}
          </>
        )}

        {activeTab === 'biasReport' &&
          ((modelType !== 'object' &&
            biasReportChartData['numerics']?.[columnName] &&
            Object.keys(biasReportChartData['numerics'][columnName]).length > 0) ||
            (modelType === 'object' &&
              biasReportChartData['categoricals']?.[columnName] &&
              Object.keys(biasReportChartData['categoricals'][columnName]).length > 0)) && (
            <>
              <div style={descriptionStyles}>
                <span>
                  Select a categorical variable to examine the distributions of two samples for a comparative bias
                  analysis.
                </span>
              </div>
              <ProcessingBiasReport
                modelType={modelType}
                columnName={columnName}
                biasReportChartData={biasReportChartData}
                biasReportCategoricalsData={biasReportCategoricalsData}
              />
            </>
          )}
      </div>
    </Box>
  );
};

export default ProcessingTableSummary;
