import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const selector = (state: RootState) => state.dataProcessing;

const dataProcessingData = createSelector([selector], (state) => state);

export default {
  dataProcessingData
};
