import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

import MainCard from '../MainCard';

// table columns
const columns = [
  { field: 'column_name', headerName: 'Column name' },
  { field: 'type', headerName: 'Type' },
  { field: 'na_values', headerName: 'NA values' },
  { field: 'mean_or_mode', headerName: 'Mean or Mode' },
  { field: 'max', headerName: 'Max' },
  { field: 'min', headerName: 'Min' },
  { field: 'unique_value', headerName: 'Unique values' }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px' // set the maximum width here
  },
  '&:hover': {
    '&::after': {
      content: 'attr(title)',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#333',
      color: '#fff',
      padding: '5px',
      zIndex: 1,
      left: '100%',
      top: '0',
      whiteSpace: 'normal',
      width: 'auto',
      minWidth: '100px',
      textAlign: 'center'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-of-type td, &:last-of-type th': {
    border: 0
  }
}));

const InfoTable = ({ summaryData }: any) => (
  <>
    {summaryData.summary !== null && Object.keys(summaryData).length > 0 ? (
      <MainCard content={false} sx={{ width: '100%' }}>
        <TableContainer sx={{ width: '100%', maxHeight: '500px' }}>
          <Table sx={{ width: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map((headCell) => (
                  <StyledTableCell sx={{ pl: 3 }} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    {headCell.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: 'calc(100% - 48px)', overflowY: 'scroll' }}>
              {Object.keys(summaryData).length > 0 &&
                summaryData.summary.data_summary.map((row: any) => (
                  <StyledTableRow hover key={row.column_name}>
                    <StyledTableCell title={row.column_name}>{row.column_name}</StyledTableCell>
                    <StyledTableCell title={row.type}>
                      {row.type === 'object' ? 'Categorical' : row.type === 'datetime64[ns]' ? 'Datetime' : 'Numeric'}
                    </StyledTableCell>
                    <StyledTableCell title={row.na_values}>{row.na_values}</StyledTableCell>
                    <StyledTableCell title={row.mean_or_mode}>{row.mean_or_mode}</StyledTableCell>
                    <StyledTableCell title={row.max}>{row.max}</StyledTableCell>
                    <StyledTableCell title={row.min}>{row.min}</StyledTableCell>
                    <StyledTableCell
                      title={
                        row.unique_values.length > 200 ? `${row.unique_values.substring(0, 200)}...` : row.unique_values
                      }
                    >
                      {row.unique_values}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh'
          // marginTop: '-100px',
          // marginLeft: '-480px'
        }}
      >
        <CircularProgress style={{ color: 'blue' }} />
      </div>
    )}
  </>
);

export default InfoTable;
