import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Autocomplete, Button, CircularProgress, TextField } from '@mui/material';
import { validationSchema, initialValues } from './processingMergeSelectDataset.constants';
import {
  autocompleteIconStyles,
  autocompleteLiStyles,
  autocompletePlaceholderStyles,
  autocompleteStyles,
  cloudIconStyles,
  containerStyles,
  formContainerStyles,
  inputButtonDisabledStyles,
  inputButtonIconInnerStyles,
  inputButtonIconStyles,
  inputButtonStyles,
  inputStyles,
  labelFileNameStyles,
  labelStyles,
  orStyles,
  uploadButtonSpanStyles,
  uploadButtonStyles
} from './processingMergeSelectDataset.styles';
import { CloudUpload, Search } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { ReactComponent as VectorBoxIcon } from './../../../../../assets/svg/Vector.svg';
import { IProcessingMergeSelectDataset } from './processingMergeSelectDataset.models';
import { dispatch, useAppSelector } from '../../../../../store/hooks';
import { datasourceUsersMiddleware, datasourceUsersSelector } from '../../../../../store/slices/datasourceUsers';
import { removeLastExtension } from '../../../../../helpers/removeExtension';
import { uploadMiddleware } from '../../../../../store/slices/upload';
import { uploadStatusMiddleware } from '../../../../../store/slices/uploadStatus';
import API from '../../../../../manager/API';
import { storageMiddleware } from '../../../../../store/slices/storage';

const ProcessingMergeSelectDataset: React.FC<IProcessingMergeSelectDataset> = ({
  summaryData,
  processingMergeInputData,
  setProcessingMergeInputData,
  processingMergeActiveStep,
  setProcessingMergeActiveStep,
  processingMergeUploadStatus,
  setProcessingMergeUploadStatus,
  processingMergeUploadProgress,
  setProcessingMergeUploadProgress,
  processingMergeUploadSucceeded,
  setProcessingMergeUploadSucceeded,
  setDatasetOrUpload
}) => {
  const { datasourceUsersData } = useAppSelector(datasourceUsersSelector.DatasourceUsersData);
  const getDatasourceUsersURL = `/datasource/user_datasources`;
  const [loading, setLoading] = useState<boolean>(true);

  const datasetOptionsWithIds = datasourceUsersData
    .map((option: any, index: any) => ({
      id: option,
      label: removeLastExtension(option)
    }))
    .filter((option: any) => option.id !== summaryData.filename);

  const formik = useFormik({
    initialValues: {
      dataset: processingMergeInputData.dataset.length > 0 ? processingMergeInputData.dataset : '',
      uploadedFileName: '',
      uploadedFileSize: 0
    },
    validationSchema,
    onSubmit
  });

  const { values, setFieldValue } = formik;

  const handleAutocompleteChange = async (selectedOption: any) => {
    const value = selectedOption.id;
    setFieldValue('dataset', value);
  };

  const handleFileUpload = async (e: any) => {
    setProcessingMergeUploadSucceeded(false);
    const currentTarget = e.currentTarget;
    const uploadFileUrl = `/blob_file/stream_upload`;
    const getAllResourcesURL = `/datasource/all-files`;
    const file = e.target.files[0];
    const fileName = file.name.trim();
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension !== 'csv') {
      toast.error('Please select a .csv file');
      currentTarget.value = ''; // Clear the file input
      setProcessingMergeActiveStep(1);
      return;
    } else {
      setProcessingMergeActiveStep(2);
      console.log(111);
      setFieldValue('uploadedFileName', file.name);
      setFieldValue('uploadedFileSize', file.size);
      setProcessingMergeInputData({
        ...processingMergeInputData,
        uploadedFileName: file.name,
        uploadedFileSize: file.size
      });
      try {
        const formData = new FormData();
        const cleanedFile = new File([file], fileName);
        formData.append('file', cleanedFile);
        try {
          const response = await dispatch(uploadMiddleware.uploadFile(formData, uploadFileUrl));
          if (response.status !== 200) {
            setProcessingMergeActiveStep(1);
          }
          const uploadFileData = response.data;
          if (uploadFileData && Object.keys(uploadFileData).length > 0 && uploadFileData?.status_update) {
            const uploadStatusUrl = `/blob_file/data_status/${uploadFileData.status_update}`;
            const getUploadProgressURL = `/blob_file/stream_upload_progress/${uploadFileData.status_update}`;
            let intervalId: any = setInterval(async () => {
              try {
                const uploadFileStatusData = await dispatch(
                  uploadStatusMiddleware.uploadStatus(uploadFileData.status_update, uploadStatusUrl, getAllResourcesURL)
                );
                const dataOfUploadProgress = await API.uploadProgress.getUploadProgress(getUploadProgressURL);
                if (uploadFileStatusData.status === 200) {
                  if (
                    dataOfUploadProgress &&
                    dataOfUploadProgress.data.progress &&
                    dataOfUploadProgress.data.progress.length > 0
                  ) {
                    setProcessingMergeUploadProgress(dataOfUploadProgress.data.progress);
                    setProcessingMergeUploadStatus(dataOfUploadProgress.data.description);
                  }
                  if (uploadFileStatusData.data.queue_state === 'SUCCESS') {
                    setFieldValue('uploadedFileName', file.name);
                    setFieldValue('uploadedFileSize', file.size);
                    setProcessingMergeInputData({
                      ...processingMergeInputData,
                      uploadedFileName: file.name,
                      uploadedFileSize: file.size
                    });

                    setProcessingMergeUploadSucceeded(true);
                    const getStorageURL = `blob_file/get_user_storage_size`;
                    dispatch(storageMiddleware.getStorage(getStorageURL));
                    clearInterval(intervalId);
                  } else if (
                    uploadFileStatusData.data.queue_state === 'FAILURE' ||
                    (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0)
                  ) {
                    setFieldValue('uploadedFileName', '');
                    setFieldValue('uploadedFileSize', '');
                    setProcessingMergeInputData({
                      ...processingMergeInputData,
                      uploadedFileName: '',
                      uploadedFileSize: ''
                    });
                    setProcessingMergeUploadSucceeded(false);
                    setProcessingMergeActiveStep(1);
                    clearInterval(intervalId);
                    if (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0) {
                      setProcessingMergeActiveStep(1);
                      toast.warn(removeLastExtension(uploadFileStatusData.data.message));
                    } else {
                      setProcessingMergeActiveStep(1);
                      toast.error(removeLastExtension(uploadFileStatusData.data.message));
                    }
                  }
                } else {
                  clearInterval(intervalId);
                  toast.error(removeLastExtension(uploadFileStatusData.data.message));
                  setFieldValue('uploadedFileName', '');
                  setFieldValue('uploadedFileSize', '');
                  setProcessingMergeInputData({
                    ...processingMergeInputData,
                    uploadedFileName: '',
                    uploadedFileSize: ''
                  });
                  setProcessingMergeUploadSucceeded(false);
                  setProcessingMergeActiveStep(1);
                }
              } catch (error: any) {
                clearInterval(intervalId);
                toast.error(removeLastExtension(error?.response.data.message));
                setFieldValue('uploadedFileName', '');
                setFieldValue('uploadedFileSize', '');
                setProcessingMergeInputData({
                  ...processingMergeInputData,
                  uploadedFileName: '',
                  uploadedFileSize: ''
                });
                setProcessingMergeUploadSucceeded(false);
                setProcessingMergeActiveStep(1);
              }
            }, 5000);
          }
        } catch (error: any) {
          console.error('Upload failed with error:', (error as Error).message);
          toast.error(removeLastExtension(error?.response.data.message));
          setFieldValue('uploadedFileName', '');
          setFieldValue('uploadedFileSize', '');
          setProcessingMergeInputData({
            ...processingMergeInputData,
            uploadedFileName: '',
            uploadedFileSize: ''
          });
          setProcessingMergeUploadSucceeded(false);
          setProcessingMergeActiveStep(1);
        }
      } catch (error) {
        console.error('CSV validation failed:', (error as Error).message);
        setFieldValue('uploadedFileName', '');
        setFieldValue('uploadedFileSize', '');
        setProcessingMergeInputData({
          ...processingMergeInputData,
          uploadedFileName: '',
          uploadedFileSize: ''
        });
        setProcessingMergeUploadSucceeded(false);
        setProcessingMergeActiveStep(1);
      }
    }
  };

  useEffect(() => {
    setProcessingMergeInputData({ ...values });
  }, [values]);

  function onSubmit() {
    setProcessingMergeActiveStep(3);
    setDatasetOrUpload('dataset');
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(datasourceUsersMiddleware.getDatasourceUsers(getDatasourceUsersURL));
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form style={formContainerStyles}>
          <div style={containerStyles}>
            <span style={labelStyles}>
              Select a dataset to combine it with <span style={labelFileNameStyles}>{summaryData.filename}</span>
            </span>

            <div>
              <Button
                component="label"
                variant="contained"
                size="large"
                style={uploadButtonStyles}
                startIcon={<CloudUpload style={cloudIconStyles} />}
                sx={uploadButtonSpanStyles}
              >
                Upload Dataset
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
              <span style={orStyles}>or</span>
              <div style={inputStyles}>
                <Autocomplete
                  value={datasetOptionsWithIds.find((option: any) => option.id === values.dataset) || null}
                  options={datasetOptionsWithIds}
                  onChange={(event, newValue) => handleAutocompleteChange(newValue)}
                  loading={loading}
                  noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'}
                  sx={autocompleteStyles}
                  renderOption={(props, option) => (
                    <li {...props} style={autocompleteLiStyles}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search dataset"
                      label=""
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search style={autocompleteIconStyles} />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        style: autocompleteLiStyles
                      }}
                      sx={autocompletePlaceholderStyles}
                    />
                  )}
                  componentsProps={{
                    clearIndicator: {
                      onClick: () => setFieldValue('dataset', '')
                    }
                  }}
                  disableClearable={!values.dataset}
                />
                <Button
                  variant="contained"
                  style={processingMergeInputData.dataset.length > 0 ? inputButtonStyles : inputButtonDisabledStyles}
                  onClick={onSubmit}
                  disabled={processingMergeInputData.dataset.length === 0}
                >
                  <span style={inputButtonIconStyles}>
                    <VectorBoxIcon style={inputButtonIconInnerStyles} />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ProcessingMergeSelectDataset;
