import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';
import { toast } from 'react-toastify';

export const { setDataProcessingData, setDataProcessingStatus } = slice.actions;

const dataProcessing = (params: any, url: any) => async () => {
  try {
    const response = await API.dataProcessing.dataProcessing(params, url);
    dispatch(setDataProcessingData(response.data));
    dispatch(setDataProcessingStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error: any) {
    if (error.response.status === 422) {
      toast.error(error.response.data.message);
    }
  }
};

export default {
  dataProcessing
};
