import React, { useEffect, useState } from 'react';
import { ReactComponent as StepDeleteIcon } from '../../../../../../assets/svg/stepDeleteIcon.svg';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { IProcessingMergeJoinInnerBlock } from './processingMergeJoinInnerBlock.models';
import {
  columnsAutocompleteLiStyles,
  columnsAutocompletePlaceholderStyles,
  columnsAutocompleteStyles,
  removeIconStyles,
  spanStyles,
  wholeStyles
} from './processingMergeJopinInnerBlock.styles';

const ProcessingMergeJoinInnerBlock: React.FC<IProcessingMergeJoinInnerBlock> = ({
  summaryData,
  processingMergeInputData,
  setBlocks,
  block,
  blocks,
  element,
  deleteBlock
}) => {
  const innerBlockIndex = blocks.findIndex((b: any) => b.id === block?.id);
  console.log(innerBlockIndex);
  return (
    <div style={wholeStyles}>
      <div>
        <span style={spanStyles}>{summaryData.filename}</span>
        <Autocomplete
          value=""
          options={[]}
          // onChange={handleColumnsChange}
          sx={columnsAutocompleteStyles(blocks)}
          // renderOption={(props, option) => (
          //   <li {...props} style={columnsAutocompleteLiStyles}>
          //     {option.label}
          //   </li>
          // )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select column"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          // componentsProps={{
          //   clearIndicator: {
          //     onClick: () => setSelectedColumns([])
          //   }
          // }}
        />
      </div>

      <div>
        <span style={spanStyles}>{processingMergeInputData.dataset}</span>
        <Autocomplete
          value=""
          options={[]}
          // onChange={handleSecondColumnsChange}
          sx={columnsAutocompleteStyles(blocks)}
          // renderOption={(props, option) => (
          //   <li {...props} style={columnsAutocompleteLiStyles}>
          //     {option.label}
          //   </li>
          // )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="select column"
              label=""
              InputProps={{
                ...params.InputProps,
                style: columnsAutocompleteLiStyles
              }}
              sx={columnsAutocompletePlaceholderStyles}
            />
          )}
          // componentsProps={{
          //   clearIndicator: {
          //     onClick: () => setSelectedSecondColumns([])
          //   }
          // }}
        />
      </div>

      {blocks.length > 1 && (
        <IconButton style={removeIconStyles(innerBlockIndex)} onClick={() => deleteBlock(element)} disableRipple={true}>
          <StepDeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ProcessingMergeJoinInnerBlock;
