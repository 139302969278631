import React, { useEffect, useState } from 'react';
import { IProcessingMergeBody } from './processingMergeBody.models';
import ProcessingMergeSelectDataset from './processingMergeSelectDataset/processingMergeSelectDataset.component';
import ProcessingMergeUploadDataset from './processingMergeUploadDataset/processingMergeUploadDataset.component';
import ProcessingMergeJoin from './processingMergeJoin/processingMergeJoin.component';

const ProcessingMergeBody: React.FC<IProcessingMergeBody> = ({
  processingMergeJoinBlocks,
  setProcessingMergeJoinBlocks,
  summaryData,
  processingMergeInputData,
  setProcessingMergeInputData,
  processingMergeActiveStep,
  setProcessingMergeActiveStep,
  processingMergeUploadStatus,
  setProcessingMergeUploadStatus,
  processingMergeUploadProgress,
  setProcessingMergeUploadProgress,
  processingMergeUploadSucceeded,
  setProcessingMergeUploadSucceeded,
  processingMergeNewFileNameValue,
  setProcessingMergeNewFileNameValue
}) => {
  return (
    <>
      {processingMergeActiveStep === 1 && (
        <ProcessingMergeSelectDataset
          processingMergeInputData={processingMergeInputData}
          setProcessingMergeInputData={setProcessingMergeInputData}
          summaryData={summaryData}
          processingMergeActiveStep={processingMergeActiveStep}
          setProcessingMergeActiveStep={setProcessingMergeActiveStep}
          processingMergeUploadStatus={processingMergeUploadStatus}
          setProcessingMergeUploadStatus={setProcessingMergeUploadStatus}
          processingMergeUploadProgress={processingMergeUploadProgress}
          setProcessingMergeUploadProgress={setProcessingMergeUploadProgress}
          processingMergeUploadSucceeded={processingMergeUploadSucceeded}
          setProcessingMergeUploadSucceeded={setProcessingMergeUploadSucceeded}
          setDatasetOrUpload=""
        />
      )}
      {processingMergeActiveStep === 2 && (
        <ProcessingMergeUploadDataset
          summaryData={summaryData}
          processingMergeInputData={processingMergeInputData}
          setProcessingMergeActiveStep={setProcessingMergeActiveStep}
          processingMergeUploadStatus={processingMergeUploadStatus}
          setProcessingMergeUploadStatus={setProcessingMergeUploadStatus}
          processingMergeUploadProgress={processingMergeUploadProgress}
          setProcessingMergeUploadProgress={setProcessingMergeUploadProgress}
          processingMergeUploadSucceeded={processingMergeUploadSucceeded}
          setProcessingMergeUploadSucceeded={setProcessingMergeUploadSucceeded}
        />
      )}
      {processingMergeActiveStep === 3 && (
        <ProcessingMergeJoin
          processingMergeJoinBlocks={processingMergeJoinBlocks}
          setProcessingMergeJoinBlocks={setProcessingMergeJoinBlocks}
          summaryData={summaryData}
          processingMergeInputData={processingMergeInputData}
          processingMergeNewFileNameValue={processingMergeNewFileNameValue}
          setProcessingMergeNewFileNameValue={setProcessingMergeNewFileNameValue}
        />
      )}
    </>
  );
};

export default ProcessingMergeBody;
