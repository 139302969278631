import React, { useEffect, useState } from 'react';
import {
  columnNameInputStyles,
  startFromRangeStyles,
  endRangeStyles,
  removeIconStyles,
  blockWrapperStyles,
  optionalNameStyles
} from './convertNumericToCategoricalInnerBlock.styles';
import { IConvertNumericToCategoricalInnerBlock } from './convertNumericToCategoricalInnerBlock.models';
import { ReactComponent as StepDeleteIcon } from '../../../../../../../assets/svg/stepDeleteIcon.svg';
import { IconButton } from '@mui/material';

const ConvertNumericToCategoricalInnerBlock: React.FC<IConvertNumericToCategoricalInnerBlock> = ({
  startError,
  setStartError,
  endError,
  setEndError,
  setInnerBlocks,
  updateBlockBody,
  deleteInnerBlock,
  element,
  innerBlock,
  innerBlocks
}) => {
  const [startFromRangeInputValue, setStartFromRangeInputValue] = useState<string>(
    innerBlock.blockBody?.[0]?.toString() || ''
  );
  const [endRangeInputValue, setEndRangeInputValue] = useState<string>(innerBlock.blockBody?.[1]?.toString() || '');
  const [optionalNameInputValue, setOptionalNameInputValue] = useState<string>(innerBlock.blockBody?.[2] || '');

  const innerBlockIndex = innerBlocks.findIndex((b: any) => b.id === innerBlock.id);

  // Identify if this is the last row
  const isLastRow = innerBlock.id === innerBlocks[innerBlocks.length - 1].id;

  // Track whether the user has interacted with the last row
  const [lastRowTouched, setLastRowTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Function to check if ranges overlap
  const isOverlapping = (start: number, end: number) => {
    return innerBlocks.some(
      (block: any, index: any) =>
        index !== innerBlockIndex &&
        ((start >= block.blockBody[0] && start < block.blockBody[1]) || // Start inside an existing range
          (end > block.blockBody[0] && end <= block.blockBody[1]) || // End inside an existing range
          (start < block.blockBody[0] && end > block.blockBody[1])) // Fully covers an existing range
    );
  };

  // Handles input changes, allowing only numbers (including negative & floats)
  const handleNumberInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>, isStart: boolean) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/[^0-9.-]/g, ''); // Allow only numbers, decimal, and negative sign
      if (value.length <= 40) {
        setter(value);
      }

      // Mark only the last row as touched
      if (isLastRow) {
        setLastRowTouched(true);
      }

      validateRanges(
        isStart ? parseFloat(value) : parseFloat(startFromRangeInputValue),
        isStart ? parseFloat(endRangeInputValue) : parseFloat(value)
      );
    };

  // Validation logic for start and end range inputs
  const validateRanges = (start: number, end: number) => {
    let isValid = true;
    let errorMessage = '';

    // Check: end range must be greater than start range
    if (isNaN(start) || isNaN(end) || start >= end) {
      isValid = false;
      errorMessage = 'The end bin must be greater than the start bin.';
    }

    // Check: ensure no overlapping ranges
    if (isValid && isOverlapping(start, end)) {
      isValid = false;
      errorMessage = 'The binning intervals must be non-overlapping.';
    }

    // Apply error only for the last row after keydown
    setStartError(isLastRow && lastRowTouched ? !isValid : false);
    setEndError(isLastRow && lastRowTouched ? !isValid : false);

    // Show the error message based on validation
    setErrorMessage(errorMessage);
  };

  // Runs validation whenever inputs change
  useEffect(() => {
    validateRanges(parseFloat(startFromRangeInputValue), parseFloat(endRangeInputValue));

    const newActionBody = [
      parseFloat(startFromRangeInputValue),
      parseFloat(endRangeInputValue),
      optionalNameInputValue
    ];
    const updatedBlocks = updateBlockBody(innerBlocks, innerBlock.id, newActionBody);
    setInnerBlocks(updatedBlocks);
  }, [startFromRangeInputValue, endRangeInputValue, optionalNameInputValue]);

  return (
    <div>
      <div style={blockWrapperStyles}>
        {innerBlocks.length > 1 && (
          <IconButton
            style={removeIconStyles(innerBlockIndex)}
            onClick={() => deleteInnerBlock(element)}
            disableRipple={true}
          >
            <StepDeleteIcon />
          </IconButton>
        )}
        <div style={startFromRangeStyles}>
          <input
            type="text"
            value={startFromRangeInputValue}
            onChange={handleNumberInputChange(setStartFromRangeInputValue, true)}
            placeholder="start"
            style={{
              ...columnNameInputStyles,
              border: errorMessage.length > 0 && startError ? '2px solid red' : '.5px solid #D9D9D9',
              width: innerBlocks.length > 1 ? '150px' : '170px'
            }}
          />
        </div>
        <div style={endRangeStyles}>
          <input
            type="text"
            value={endRangeInputValue}
            onChange={handleNumberInputChange(setEndRangeInputValue, false)}
            placeholder="end"
            style={{
              ...columnNameInputStyles,
              border: errorMessage.length > 0 && endError ? '2px solid red' : '.5px solid #D9D9D9',
              width: innerBlocks.length > 1 ? '150px' : '170px'
            }}
          />
        </div>
        <div style={optionalNameStyles}>
          <input
            value={optionalNameInputValue}
            onChange={(e) => setOptionalNameInputValue(e.target.value.slice(0, 40))}
            placeholder="category (optional)"
            style={columnNameInputStyles}
          />
        </div>
      </div>
      {errorMessage && <div style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</div>}
    </div>
  );
};

export default ConvertNumericToCategoricalInnerBlock;
