import React, { useEffect, useMemo, useState } from 'react';

import { IProcessingPreprocessInnerBlock } from './processingPreprocessInnerBlock.models';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import {
  actionsAutocompleteLiStyles,
  actionsAutocompletePlaceholderStyles,
  actionsAutocompleteStyles,
  blockContainerStyles,
  blockDetailsStyles,
  blockWholeStyles,
  blockWrapperStyles,
  dropdownWrapperStyles,
  removeIconStyles,
  stepTitleStyles
} from './processingPreprocessInnerBlock.styles';
import { getActionsDropdownOptions, getFilteredDropdownOptions } from './processingPreprocessInnerBlock.constants';
import { ReactComponent as StepArrowUp } from './../../../../assets/svg/stepArrowUp.svg';
import { ReactComponent as StepArrowDown } from './../../../../assets/svg/stepArrowDown.svg';
import { ReactComponent as StepDeleteIcon } from './../../../../assets/svg/stepDeleteIcon.svg';
import HandleMissingValuesBody from './processingPreprocessActionsBodies/handleMissingValuesBody/handleMissingValuesBody.component';
import HandleOutliersBody from './processingPreprocessActionsBodies/handleOutliersBody/handleOutliersBody.component';
import CategoricalEncodingBody from './processingPreprocessActionsBodies/categoricalEncodingBody/categoricalEncodingBody.component';
import ManageColumnsBody from './processingPreprocessActionsBodies/manageColumnsBody/manageColumnsBody.component';
import DateTimeColumnsBody from './processingPreprocessActionsBodies/dateTimeColumnsBody/dateTimeColumnsBody.component';
import CreateNewColumnBody from './processingPreprocessActionsBodies/createNewColumnBody/createNewColumnBody.component';
import ConvertNumericToCategoricalBody from './processingPreprocessActionsBodies/convertNumericToCategoricalBody/convertNumericToCategoricalBody.component';
import { IBlock } from '../processingPreprocess.models';
import { dateTimeDropdownOptions } from './processingPreprocessActionsBodies/dateTimeColumnsBody/dateTimeColumnsBody.constants';

const ProcessingPreprocessInnerBlock: React.FC<IProcessingPreprocessInnerBlock> = ({
  modelVisualisationsData,
  selectedActionOption,
  setSelectedActionOption,
  updateSingleActionBody,
  data,
  summaryData,
  deleteBlock,
  element,
  block,
  blocks,
  setBlocks
}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
  const [idValue, setIdValue] = useState(null);

  const onlyCategoricalDropdownOptions = data
    ? Object.keys(data)
        .filter((key) => data[key].type === 'object')
        .map((key, index) => ({
          id: index,
          label: key
        }))
    : [];

  const onlyDateTimeDropdownOptions = data
    ? Object.keys(data)
        .filter((key) => data[key].type === 'datetime64[ns]')
        .map((key, index) => ({
          id: index,
          label: key
        }))
    : [];

  const onlyNumericDropdownOptions = data
    ? Object.keys(data)
        .filter((key) => data[key].type !== 'datetime64[ns]' && data[key].type !== 'object')
        .map((key, index) => ({
          id: index,
          label: key
        }))
    : [];

  const NADropdownOptions = summaryData
    ? summaryData
        .filter((item: any) => parseFloat(item.na_values) > 0) // Check if na_values is greater than 0
        .map((item: any) => ({
          type: item.type,
          label: item.column_name
        }))
    : null;

  const lessUniqueValues = summaryData
    ? summaryData.filter((item: any) => parseFloat(item.unique_values_count) > 20).map((item: any) => item.column_name) // Assuming "key" exists in each object
    : [];

  const columnsThatHaveOnlyNaValues =
    Object.keys(modelVisualisationsData).length > 0 &&
    Object.entries(modelVisualisationsData?.pure_histogram || {}) // Use Object.entries()
      .filter(([key, value]: any) => value?.message_instead === 'All values are NaN')
      .map(([key]: any) => key);

  const allTypeDropdownOptions = data
    ? Object.keys(data).map((key, index) => ({
        id: index,
        label: key
      }))
    : [];

  useEffect(() => {
    const matchingBlock = blocks.find((b: any) => b.id === block.id);
    setIdValue(matchingBlock ? matchingBlock.id : null);
  }, [blocks]);

  const blockIndex = blocks.findIndex((b: any) => b.id === block.id);

  const handleAccordionChange = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handleActionChange = (blockId: number, _: any, newValue: { id: number; label: string } | null) => {
    setBlocks((prevBlocks: IBlock[]) =>
      prevBlocks.map((block: IBlock) =>
        block.id === blockId ? { ...block, action: newValue?.label || '', singleActionBody: {} } : block
      )
    );

    setSelectedActionOption((prevOptions: Record<number, { id: number; label: string }>) => {
      const blockIndex = blocks.findIndex((b: any) => b.id === blockId);
      if (blockIndex === -1) return prevOptions;

      return {
        ...prevOptions,
        [blockIndex]: newValue ? { id: blockIndex + 1, label: newValue.label } : null
      };
    });
  };

  return (
    <div style={blockContainerStyles}>
      <div style={blockWholeStyles}>
        <div style={blockWrapperStyles(openAccordionIndex)} onClick={() => handleAccordionChange(0)}>
          <IconButton
            style={removeIconStyles(blockIndex)}
            onClick={() => {
              deleteBlock(element);
              // handleAccordionChange(0);
            }}
            disableRipple={true}
          >
            <StepDeleteIcon />
          </IconButton>
          <span style={stepTitleStyles}>Step {idValue}</span>
          {openAccordionIndex === 0 ? <StepArrowUp /> : <StepArrowDown />}
        </div>
        {openAccordionIndex === 0 && (
          <div style={blockDetailsStyles}>
            <div style={dropdownWrapperStyles}>
              <Autocomplete
                value={selectedActionOption[blocks.findIndex((b: any) => b.id === block.id)] ?? null}
                options={getActionsDropdownOptions(
                  blocks,
                  block,
                  NADropdownOptions ?? [],
                  onlyNumericDropdownOptions ?? [],
                  onlyCategoricalDropdownOptions ?? [],
                  allTypeDropdownOptions ?? [],
                  onlyDateTimeDropdownOptions ?? [],
                  dateTimeDropdownOptions ?? []
                )}
                onChange={(event, newValue) => handleActionChange(block.id, event, newValue)}
                sx={actionsAutocompleteStyles}
                renderOption={(props, option) => (
                  <li {...props} style={actionsAutocompleteLiStyles}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="select action"
                    label=""
                    InputProps={{
                      ...params.InputProps,
                      style: actionsAutocompleteLiStyles
                    }}
                    sx={actionsAutocompletePlaceholderStyles}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => handleActionChange(block.id, null, null)
                  }
                }}
                disableClearable={selectedActionOption[block.id] === null}
              />
            </div>
            {selectedActionOption[block.id - 1]?.label === 'Handle Missing Values' && (
              <HandleMissingValuesBody
                columnsThatHaveOnlyNaValues={columnsThatHaveOnlyNaValues}
                blocks={blocks}
                dropdownOptions={NADropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Handle Outliers' && (
              <HandleOutliersBody
                blocks={blocks}
                dropdownOptions={onlyNumericDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Categorical Encoding' && (
              <CategoricalEncodingBody
                lessUniqueValues={lessUniqueValues}
                blocks={blocks}
                dropdownOptions={onlyCategoricalDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Create New Columns' && (
              <CreateNewColumnBody
                blocks={blocks}
                dropdownOptions={onlyNumericDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Manage Columns' && (
              <ManageColumnsBody
                blocks={blocks}
                dropdownOptions={allTypeDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Handle Datetime Columns' && (
              <DateTimeColumnsBody
                blocks={blocks}
                dropdownOptions={onlyDateTimeDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
            {selectedActionOption[block.id - 1]?.label === 'Convert Numeric to Categorical' && (
              <ConvertNumericToCategoricalBody
                blocks={blocks}
                dropdownOptions={onlyNumericDropdownOptions}
                setBlocks={setBlocks}
                block={block}
                updateSingleActionBody={updateSingleActionBody}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessingPreprocessInnerBlock;

// import React, { useEffect, useMemo, useState } from 'react';
//
// import { IProcessingPreprocessInnerBlock } from './processingPreprocessInnerBlock.models';
// import { Autocomplete, IconButton, TextField } from '@mui/material';
// import {
//   actionsAutocompleteLiStyles,
//   actionsAutocompletePlaceholderStyles,
//   actionsAutocompleteStyles,
//   blockContainerStyles,
//   blockDetailsStyles,
//   blockWholeStyles,
//   blockWrapperStyles,
//   dropdownWrapperStyles,
//   removeIconStyles,
//   stepTitleStyles
// } from './processingPreprocessInnerBlock.styles';
// import { getActionsDropdownOptions, getFilteredDropdownOptions } from './processingPreprocessInnerBlock.constants';
// import { ReactComponent as StepArrowUp } from './../../../../assets/svg/stepArrowUp.svg';
// import { ReactComponent as StepArrowDown } from './../../../../assets/svg/stepArrowDown.svg';
// import { ReactComponent as StepDeleteIcon } from './../../../../assets/svg/stepDeleteIcon.svg';
// import HandleMissingValuesBody from './processingPreprocessActionsBodies/handleMissingValuesBody/handleMissingValuesBody.component';
// import HandleOutliersBody from './processingPreprocessActionsBodies/handleOutliersBody/handleOutliersBody.component';
// import CategoricalEncodingBody from './processingPreprocessActionsBodies/categoricalEncodingBody/categoricalEncodingBody.component';
// import ManageColumnsBody from './processingPreprocessActionsBodies/manageColumnsBody/manageColumnsBody.component';
// import DateTimeColumnsBody from './processingPreprocessActionsBodies/dateTimeColumnsBody/dateTimeColumnsBody.component';
// import CreateNewColumnBody from './processingPreprocessActionsBodies/createNewColumnBody/createNewColumnBody.component';
// import ConvertNumericToCategoricalBody from './processingPreprocessActionsBodies/convertNumericToCategoricalBody/convertNumericToCategoricalBody.component';
// import { IBlock } from '../processingPreprocess.models';
// import { dateTimeDropdownOptions } from './processingPreprocessActionsBodies/dateTimeColumnsBody/dateTimeColumnsBody.constants';
//
// const ProcessingPreprocessInnerBlock: React.FC<IProcessingPreprocessInnerBlock> = ({
//                                                                                      modelVisualisationsData,
//                                                                                      selectedActionOption,
//                                                                                      setSelectedActionOption,
//                                                                                      updateSingleActionBody,
//                                                                                      data,
//                                                                                      summaryData,
//                                                                                      deleteBlock,
//                                                                                      element,
//                                                                                      block,
//                                                                                      blocks,
//                                                                                      setBlocks
//                                                                                    }) => {
//   const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
//   const [idValue, setIdValue] = useState(null);
//
//   const onlyCategoricalDropdownOptions = data
//     ? Object.keys(data)
//       .filter((key) => data[key].type === 'object')
//       .map((key, index) => ({
//         id: index,
//         label: key
//       }))
//     : [];
//
//   const onlyDateTimeDropdownOptions = data
//     ? Object.keys(data)
//       .filter((key) => data[key].type === 'datetime64[ns]')
//       .map((key, index) => ({
//         id: index,
//         label: key
//       }))
//     : [];
//
//   const onlyNumericDropdownOptions = data
//     ? Object.keys(data)
//       .filter((key) => data[key].type !== 'datetime64[ns]' && data[key].type !== 'object')
//       .map((key, index) => ({
//         id: index,
//         label: key
//       }))
//     : [];
//
//   const NADropdownOptions = summaryData
//     ? summaryData
//       .filter((item: any) => parseFloat(item.na_values) > 0) // Check if na_values is greater than 0
//       .map((item: any) => ({
//         type: item.type,
//         label: item.column_name
//       }))
//     : null;
//
//   const columnsThatHaveOnlyNaValues =
//     Object.keys(modelVisualisationsData).length > 0 &&
//     Object.entries(modelVisualisationsData?.pure_histogram || {}) // Use Object.entries()
//       .filter(([key, value]: any) => value?.message_instead === 'All values are NaN')
//       .map(([key]: any) => key);
//
//   const allTypeDropdownOptions = data
//     ? Object.keys(data).map((key, index) => ({
//       id: index,
//       label: key
//     }))
//     : [];
//
//   useEffect(() => {
//     const matchingBlock = blocks.find((b: any) => b.id === block.id);
//     setIdValue(matchingBlock ? matchingBlock.id : null);
//   }, [blocks]);
//
//   const blockIndex = blocks.findIndex((b: any) => b.id === block.id);
//
//   const handleAccordionChange = (index: number) => {
//     setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
//   };

// const handleActionChange = (blockId: number, _: any, newValue: { id: number; label: string } | null) => {
//   const blockIndex = blocks.findIndex((b: any) => b.id === blockId);
//   if (blockIndex === -1) return;
//   setSelectedActionOption((prev: any) => {
//     const updatedOptions = { ...prev };
//     const blockIndex = Object.keys(prev).length; // Ensuring a new sequential ID
//
//     updatedOptions[blockIndex] = newValue ? { id: blockIndex + 1, label: newValue.label } : null;
//     return updatedOptions;
//   });
//
//   setBlocks((prevBlocks: IBlock[]) =>
//     prevBlocks.map((block: IBlock) =>
//       block.id === blockId ? { ...block, action: newValue?.label || '', singleActionBody: {} } : block
//     )
//   );
// };
//
//   const handleActionChange = (blockId: number, _: any, newValue: { id: number; label: string } | null) => {
//     setSelectedActionOption((prev: any) => ({
//       ...prev,
//       [blockId]: newValue
//     }));
//
//     // Update the specific block's action
//     setBlocks((prevBlocks: any) =>
//       prevBlocks.map((block: IBlock) =>
//         block.id === blockId ? { ...block, action: newValue?.label || '', singleActionBody: {} } : block
//       )
//     );
//   };
//
//   return (
//     <div style={blockContainerStyles}>
//       <div style={blockWholeStyles}>
//         <div style={blockWrapperStyles(openAccordionIndex)} onClick={() => handleAccordionChange(0)}>
//           <IconButton style={removeIconStyles(blockIndex)} onClick={() => deleteBlock(element)} disableRipple={true}>
//             <StepDeleteIcon />
//           </IconButton>
//           <span style={stepTitleStyles}>Step {idValue}</span>
//           {openAccordionIndex === 0 ? <StepArrowUp /> : <StepArrowDown />}
//         </div>
//         {openAccordionIndex === 0 && (
//           <div style={blockDetailsStyles}>
//             <div style={dropdownWrapperStyles}>
//               <Autocomplete
//                 value={selectedActionOption[block.id] || null}
//                 options={getActionsDropdownOptions(
//                   blocks,
//                   block,
//                   NADropdownOptions ?? [],
//                   onlyNumericDropdownOptions ?? [],
//                   onlyCategoricalDropdownOptions ?? [],
//                   allTypeDropdownOptions ?? [],
//                   onlyDateTimeDropdownOptions ?? [],
//                   dateTimeDropdownOptions ?? []
//                 )}
//                 onChange={(event, newValue) => handleActionChange(block.id, event, newValue)}
//                 sx={actionsAutocompleteStyles}
//                 renderOption={(props, option) => (
//                   <li {...props} style={actionsAutocompleteLiStyles}>
//                     {option.label}
//                   </li>
//                 )}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     placeholder="Select an action"
//                     label=""
//                     InputProps={{
//                       ...params.InputProps,
//                       style: actionsAutocompleteLiStyles
//                     }}
//                     sx={actionsAutocompletePlaceholderStyles}
//                   />
//                 )}
//                 componentsProps={{
//                   clearIndicator: {
//                     onClick: () => handleActionChange(block.id, null, null)
//                   }
//                 }}
//                 disableClearable={selectedActionOption[block.id] === null}
//               />
//             </div>
//             {selectedActionOption[block.id]?.id === 0 && (
//               <HandleMissingValuesBody
//                 columnsThatHaveOnlyNaValues={columnsThatHaveOnlyNaValues}
//                 blocks={blocks}
//                 dropdownOptions={NADropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 1 && (
//               <HandleOutliersBody
//                 blocks={blocks}
//                 dropdownOptions={onlyNumericDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 2 && (
//               <CategoricalEncodingBody
//                 blocks={blocks}
//                 dropdownOptions={onlyCategoricalDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 3 && (
//               <CreateNewColumnBody
//                 blocks={blocks}
//                 dropdownOptions={onlyNumericDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 4 && (
//               <ManageColumnsBody
//                 blocks={blocks}
//                 dropdownOptions={allTypeDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 5 && (
//               <DateTimeColumnsBody
//                 blocks={blocks}
//                 dropdownOptions={onlyDateTimeDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//             {selectedActionOption[block.id]?.id === 6 && (
//               <ConvertNumericToCategoricalBody
//                 blocks={blocks}
//                 dropdownOptions={onlyNumericDropdownOptions}
//                 setBlocks={setBlocks}
//                 block={block}
//                 updateSingleActionBody={updateSingleActionBody}
//               />
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
//
// export default ProcessingPreprocessInnerBlock;
