import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  titleStyles,
  closeIconStyles,
  titleBoxStyles,
  borderBottomStyles,
  newFileCheckboxLabelStyles,
  wholeNewFileStyles,
  newFileCheckboxStyles,
  newFileLabelStyles,
  extensionErrorStyles,
  newFilenameInputStyles,
  scrollBlockStyles,
  buttonsStyles,
  discardButtonStyles,
  saveButtonStyles,
  boxStyles
} from './processingPreprocess.styles';
import { IBlock, IProcessingPreprocess } from './processingPreprocess.models';
import { AddPreprocessButton } from '../../../components/Buttons/addPreprocessButton';
import ProcessingPreprocessInnerBlock from './processingPreprocessInnerBlock/processingPreprocessInnerBlock.component';
import { emptyBlock } from './processingPreprocess.constants';
import { makeStyles } from '@mui/styles';
import ProcessingSaveModal from '../processingSaveModal/processingSaveModal.component';
import { toast } from 'react-toastify';
import { dispatch, useAppSelector } from '../../../store/hooks';
import { dataProcessingMiddleware } from '../../../store/slices/dataProcessing';
import { dataProcessingStatusMiddleware } from '../../../store/slices/dataProcessingStatus';
import { dataProcessingProgressMiddleware } from '../../../store/slices/dataProcessingProgress';
import { storageMiddleware } from '../../../store/slices/storage';
import { removeLastExtension } from '../../../helpers/removeExtension';
import { summaryMiddleware } from '../../../store/slices/summary';
import { modelVisualisationsMiddleware, modelVisualisationsSelector } from '../../../store/slices/modelVisualisations';
import API from '../../../manager/API';
const useStyles = makeStyles({
  root: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none', // Hide scrollbar in Firefox
    '-ms-overflow-style': 'none' // Hide scrollbar in IE and Edge
  }
});

const ProcessingPreprocess: React.FC<IProcessingPreprocess> = ({
  isChecked,
  setIsChecked,
  newFileNameValue,
  setNewFileNameValue,
  open,
  onClose,
  data,
  setData,
  summaryData,
  blocks,
  setBlocks,
  setSelectedActionOption,
  selectedActionOption
}) => {
  const classes = useStyles();
  const [extensionError, setExtensionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [saveProgress, setSaveProgress] = useState<number>(0);
  const [saveStatus, setSaveStatus] = useState<string>('');
  const { modelVisualisationsData } = useAppSelector(modelVisualisationsSelector.modelVisualisationsData);

  const isDisabled =
    blocks?.every((block: IBlock) => block.singleActionBody?.isCompleted === true) &&
    (!isChecked || (isChecked && newFileNameValue.length > 0 && !extensionError));

  const steps = blocks.map((block: IBlock) => ({
    action: block.action || [],
    singleActionBody: block.singleActionBody
      ? Object.fromEntries(
          Object.entries(block.singleActionBody).filter(
            ([key]) => key !== 'selectedColumnsTypes' && key !== 'isCompleted'
          )
        )
      : block.singleActionBody
  }));

  const handleCloseModal = () => {
    setIsChecked(false);
    setNewFileNameValue('');
    setModalOpen(false);
    setSaveProgress(0);
    setSaveStatus('');
  };

  const handleNewFileNameCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const newFileNameValueChange = (event: any) => {
    const value = event.target.value;

    const validPattern = /^[a-zA-Z0-9_-]*$/;

    if (validPattern.test(value)) {
      setNewFileNameValue(value);
      if (value.startsWith('-') || value.startsWith('_') || value.endsWith('-') || value.endsWith('_')) {
        setExtensionError(true);
        setErrorMessage('The dataset name cannot start and end with a special character.');
      } else {
        setExtensionError(false);
        setErrorMessage('');
      }
    } else {
      setNewFileNameValue(value);
      setExtensionError(true);
      setErrorMessage('Spaces or special symbols are not allowed.');
    }
  };

  const onPlusClick = () => {
    if (!blocks.length) {
      setBlocks([{ id: 0, action: '', singleActionBody: {} }]);
    } else if (blocks.length < 10) {
      setBlocks((prev: any) => [...prev, { id: blocks[blocks.length - 1].id + 1, action: '', singleActionBody: {} }]);
    }
  };

  const handleDiscard = () => {
    setBlocks([emptyBlock({ id: 1, action: '' })]);
    setSelectedActionOption({});
  };

  const handleSave = async () => {
    const params = {
      file_name: data.filename,
      is_transform: newFileNameValue.length <= 0,
      new_filename: newFileNameValue.length > 0 ? newFileNameValue + '.csv' : '',
      schemas: steps
    };
    setModalOpen(true);
    try {
      const dataProcessingUrl = '/data_operations/data_processing';
      const response = await dispatch(dataProcessingMiddleware.dataProcessing(params, dataProcessingUrl));
      if (response?.status !== 200) {
        handleCloseModal();
      }
      const saveData = response?.data;
      if (saveData && Object.keys(saveData).length > 0 && saveData?.status_update) {
        const saveStatusUrl = `/data_operations/preprocessing_status/${saveData.status_update}`;
        const getSaveProgressURL = `/data_operations/data_processing_progress/${saveData.status_update}`;
        let intervalId: any = setInterval(async () => {
          try {
            const saveStatusData = await dispatch(dataProcessingStatusMiddleware.dataProcessingStatus(saveStatusUrl));
            const dataOfSaveProgress = await dispatch(
              dataProcessingProgressMiddleware.getDataProcessingProgress(getSaveProgressURL)
            );
            if (saveStatusData.status === 200) {
              if (
                dataOfSaveProgress &&
                dataOfSaveProgress.data.progress &&
                dataOfSaveProgress.data.progress.length > 0
              ) {
                setSaveProgress(dataOfSaveProgress.data.progress);
                setSaveStatus(dataOfSaveProgress.data.description);
              }

              if (saveStatusData.data.queue_state === 'SUCCESS') {
                const getStorageURL = `blob_file/get_user_storage_size`;
                dispatch(storageMiddleware.getStorage(getStorageURL));
                clearInterval(intervalId);
                handleCloseModal();
                onClose();
                // setModelVisualisationsStatus(0);
                toast.success(saveStatusData.data.status);
                const getPreviewUrl = `/data_operations/file_preview?filename=${
                  newFileNameValue.length > 0 ? newFileNameValue + '.csv' : data.filename
                }`;
                const getSummaryUrl = `/data_operations/data_summary?file_name=${
                  newFileNameValue.length > 0 ? newFileNameValue + '.csv' : data.filename
                }`;
                const getVisualisationsUrl = `/visualisations/get_visualisations?id=${data?.file_id}`;
                const fetchPreviewData = async () => {
                  try {
                    const response = await API.preview.getPreview(getPreviewUrl);
                    if (!response) {
                      throw new Error('Failed to fetch preview data');
                    }
                    if (response.data && response.data.file_id) {
                      console.log(response.data);
                      const getVisualisationsUrl = `/visualisations/get_visualisations?id=${response.data.file_id}`;
                      dispatch(modelVisualisationsMiddleware.getModelVisualisations(getVisualisationsUrl));
                    }
                    setData(response.data);
                  } catch (error) {
                    console.error('Error fetching preview data:', error);
                  } finally {
                  }
                };

                fetchPreviewData();
                dispatch(summaryMiddleware.getSummary(getSummaryUrl));
                // dispatch(modelVisualisationsMiddleware.getModelVisualisations(getVisualisationsUrl));
              } else if (
                saveStatusData.data.queue_state === 'FAILURE' ||
                (saveStatusData && Object.keys(saveStatusData).length === 0)
              ) {
                handleCloseModal();
                clearInterval(intervalId);
                if (saveStatusData && Object.keys(saveStatusData).length === 0) {
                  handleCloseModal();
                  toast.error(removeLastExtension(saveStatusData.data.message));
                } else {
                  handleCloseModal();
                  toast.error(removeLastExtension(saveStatusData.data.message));
                }
              }
            } else {
              clearInterval(intervalId);
              toast.error(removeLastExtension(saveStatusData.data.message));
              handleCloseModal();
            }
          } catch (error: any) {
            clearInterval(intervalId);
            toast.error(removeLastExtension(error?.response.data.message));
            handleCloseModal();
          }
        }, 5000);
      }
    } catch (error: any) {
      console.error('Upload failed with error:', error?.message);
      toast.error(removeLastExtension(error?.response?.data?.message));
      handleCloseModal();
    }
  };

  const updateSingleActionBody = (blocks: IBlock[], targetBlockId: number, newSingleActionBody: any) => {
    return blocks.map((block) => {
      if (block.id === targetBlockId) {
        return {
          ...block,
          singleActionBody: { ...newSingleActionBody } // Update the block's singleActionBody
        };
      }
      return block;
    });
  };

  const deleteBlock = (blockId: number) => {
    setBlocks((prevBlocks: IBlock[]) => {
      const updatedBlocks = prevBlocks.filter((block) => block.id !== blockId);
      return updatedBlocks.map((block, index) => ({
        ...block,
        id: index + 1 // Reassign sequential IDs
      }));
    });

    setSelectedActionOption((prevOptions: Record<number, { id: number; label: string }>) => {
      const updatedOptions = Object.keys(prevOptions)
        .map(Number)
        .filter((key) => key + 1 !== blockId) // Adjust indexing based on block deletion
        .sort((a, b) => a - b) // Ensure order
        .reduce((acc: Record<number, { id: number; label: string }>, key, index) => {
          acc[index] = { ...prevOptions[key], id: index + 1 };
          return acc;
        }, {});
      return updatedOptions;
    });
  };

  useEffect(() => {
    setNewFileNameValue('');
  }, [isChecked]);

  // console.log(blocks);
  // console.log(selectedActionOption);

  return (
    <>
      <Box sx={boxStyles(open)}>
        <Box sx={titleBoxStyles}>
          <span style={titleStyles}>Data Preprocessing</span>
          <IconButton onClick={onClose} sx={closeIconStyles}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div style={borderBottomStyles}></div>
        <div style={wholeNewFileStyles}>
          <div style={newFileCheckboxLabelStyles}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleNewFileNameCheckboxChange}
              style={newFileCheckboxStyles}
            />
            <label style={newFileLabelStyles}>Create new dataset after preprocessing actions below?</label>
          </div>
          {isChecked && (
            <>
              <input
                style={newFilenameInputStyles}
                onChange={newFileNameValueChange}
                placeholder="new dataset name"
                value={newFileNameValue}
              />
              {extensionError && <p style={extensionErrorStyles}>{errorMessage}</p>}
            </>
          )}
        </div>
        <div className={classes.root} style={scrollBlockStyles}>
          {blocks.map((block, index) => (
            <div key={block.id}>
              <ProcessingPreprocessInnerBlock
                modelVisualisationsData={modelVisualisationsData}
                selectedActionOption={selectedActionOption}
                setSelectedActionOption={setSelectedActionOption}
                updateSingleActionBody={updateSingleActionBody}
                setBlocks={setBlocks}
                data={data?.preview_data}
                summaryData={summaryData?.summary?.data_summary}
                block={block}
                blocks={blocks}
                element={block.id}
                deleteBlock={deleteBlock}
              />
            </div>
          ))}
          {blocks.length <= 9 && (
            <AddPreprocessButton
              convert={false}
              data={data?.preview_data}
              blocks={blocks}
              handlePlusButtonClick={onPlusClick}
              title="Add Step"
            />
          )}
        </div>

        <div style={buttonsStyles}>
          <button style={discardButtonStyles} onClick={handleDiscard}>
            Discard
          </button>
          <button onClick={handleSave} style={saveButtonStyles(isDisabled)} disabled={!isDisabled}>
            Save
          </button>
        </div>
      </Box>
      <ProcessingSaveModal
        open={modalOpen}
        onClose={handleCloseModal}
        uploadStatus={saveStatus}
        setUploadStatus={setSaveStatus}
        progress={saveProgress}
        setProgress={setSaveProgress}
        onContinue={handleCloseModal}
        fileName={newFileNameValue.length > 0 ? newFileNameValue : summaryData?.filename}
        fileSize={summaryData.file_size}
      />
    </>
  );
};

export default ProcessingPreprocess;
